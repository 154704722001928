import React from 'react'

import logo from '../assets/images/rat.png'

import './Logo.css'

const Logo = props => {

  return <img className='logo' src={logo} alt='logo' />
}

export default Logo