import React, { useState } from 'react'
import Logo from './Logo'
import { connect } from 'react-redux'
import { getUrl } from '../utils/urls'
import { loaderTransition } from '../utils/transitions'
import { withRouter } from 'react-router-dom'
import soma_logo from '../assets/images/SOMA.png'
import ratattack_logo from '../assets/images/rat.png'
import { langScheme } from '../utils/langResources'

import './Header.css'

const Header = ({ dispatch, appState: { lang }, hideLogo = false, time = 2400, history, onStartTransition = () => null }) => {

	const [isMenuActive, setIsMenuActive] = useState(false)
		, onClickMenuBtn = () => { setIsMenuActive(!isMenuActive) }
		, texts = langScheme('header')
		, onSetPage = (url) => {
			setIsMenuActive(false)
			setTimeout(() => {
				loaderTransition(onStartTransition)
				setTimeout(() => {
					history.push(url)
				}, time)
			}, 600)
		}

	const isActive = (path) => {
		let { pathname } = history.location
			, url = getUrl(path)
			, className = ''

		if (pathname.search(url) !== -1)
			className = 'active'
		if (path === 'home' && pathname.length !== 1)
			className = ''
		return className
	}


	return <React.Fragment>
		<header id='menu' className='header font_menu'>
			<span onClick={() => onSetPage(getUrl('home'))}>
				{
					!hideLogo
						? <Logo />
						: null
				}
			</span>
			{
				!isMenuActive
					? <span
						onClick={onClickMenuBtn}
						className='menu-button'
						dangerouslySetInnerHTML={{ __html: `<?xml version="1.0" ?><!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg height="32px" id="Layer_1" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/></svg>` }}
					/>
					: null
			}
			<nav className={isMenuActive ? '' : 'menu-inactive'}>
				<span
					onClick={onClickMenuBtn}
					className='menu-button close-btn-menu'
					dangerouslySetInnerHTML={{ __html: `<?xml version="1.0" ?><!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg height="32px" id="Layer_1" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/></svg>` }}
				/>
				<span className={`home-button ${isActive('home')}`} onClick={() => onSetPage(getUrl('home'))}>
					{texts('home')}
				</span>
				<span className={`${isActive('episode')}`} onClick={() => onSetPage(getUrl('episodes'))}>
					{texts('episodes')}
				</span>
				<span className={`${isActive('public-program')}`} onClick={() => onSetPage(getUrl('public-program'))}>
					{texts('public_program')}
				</span>
				<span className={`${isActive('expo')}`} onClick={() => onSetPage(getUrl('expo'))}>
					{texts('expo')}
				</span>
				<span id='semblance-logo' className={`${isActive('semblance')}`} onClick={() => onSetPage(getUrl('semblance'))}>
					<img className="soma_logo" src={soma_logo} alt='soma-logo' />
				</span>
				{/* <span onClick={onSetLang}>{texts('lang')}</span> */}
			</nav>
		</header>
		<nav className={`mobile-nav ${isMenuActive ? '' : 'menu-inactive'}`}>
			<div className='menu'>
				<span
					onClick={onClickMenuBtn}
					className='menu-button close-btn-menu'
					dangerouslySetInnerHTML={{ __html: `<?xml version="1.0" ?><!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg height="32px" id="Layer_1" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/></svg>` }}
				/>
				<div id="mobile_menu_logo">
					<img src={ratattack_logo} alt='logo' />
				</div>
				<span className={`home-button ${isActive('home')}`} onClick={() => onSetPage(getUrl('home'))}>
					{texts('home')}
				</span>
				<span className={`${isActive('episode')}`} onClick={() => onSetPage(getUrl('episodes'))}>
					{texts('episodes')}
				</span>
				<span className={`${isActive('public-program')}`} onClick={() => onSetPage(getUrl('public-program'))}>
					{texts('public_program')}
				</span>
				<span className={`${isActive('expo')}`} onClick={() => onSetPage(getUrl('expo'))}>
					{texts('expo')}
				</span>

				<span id='semblance-logo' className={`${isActive('semblance')}`} onClick={() => onSetPage(getUrl('semblance'))}>
					<img className="soma_logo" src={soma_logo} alt='soma-logo' />
				</span>
				{/* <span onClick={onSetLang}>{texts('lang')}</span> */}
			</div>
		</nav>
	</React.Fragment>
}

const mapStateToProps = state => ({
	appState: state.appState
})


export default connect(mapStateToProps)(withRouter(Header))