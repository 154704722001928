import React, { useEffect } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import { getUrl } from './utils/urls'

import PublicProgram from './screens/PublicProgram'
import Appointment from './screens/Appointment'
import Episode from './screens/Episode'
import Episodes from './screens/Episodes'
import PrivacyNotification from './screens/PrivacyNotification'
import Home from './screens/Home'
import Expo from './screens/Expo'
import Semblance from './screens/Semblance'
import Loader from './baseComponents/Loader'


import './App.css'

const App = (props) => {

  useEffect(() => {
    props.history.listen(location => {
      window.scrollTo(0, 0)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  console.log(getUrl('privacy'))
  return <React.Fragment>
    <Loader />
    <Switch>
      <Route exact path={getUrl('home')} component={Home} />
      <Route exact path={getUrl('appointment')} component={Appointment} />
      <Route exact path={getUrl('episodes')} component={Episodes} />
      <Route exact path={`${getUrl('episode')}/:id`} component={Episode} />
      <Route exact path={`${getUrl('public-program')}`} component={PublicProgram} />
      <Route exact path={getUrl('semblance')} component={Semblance} />
      <Route exact path={getUrl('privacy')} component={PrivacyNotification} />
      <Route exact path={getUrl('expo')} component={Expo} />
    </Switch>
  </React.Fragment>
}

export default withRouter(App)