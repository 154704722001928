export const accents = text => {
  let paragraph = text
  paragraph = paragraph.replace(/ñ/g, '<span class="ene">n</span>')
  paragraph = paragraph.replace(/Ñ/g, '<span class="ene">N</span>')
  paragraph = paragraph.normalize("NFD")
  paragraph = paragraph.replace(/á/g, '<i>a</i>')
  paragraph = paragraph.replace(/Á/g, '<i>A</i>')
  paragraph = paragraph.replace(/é/g, '<i>e</i>')
  paragraph = paragraph.replace(/É/g, '<i>E</i>')
  paragraph = paragraph.replace(/í/g, '<i>i</i>')
  paragraph = paragraph.replace(/Í/g, '<i>I</i>')
  paragraph = paragraph.replace(/ó/g, '<i>o</i>')
  paragraph = paragraph.replace(/Ó/g, '<i>O</i>')
  paragraph = paragraph.replace(/ú/g, '<i>u</i>')
  paragraph = paragraph.replace(/Ú/g, '<i>U</i>')
  paragraph = paragraph.replace(/¡/g, '<span class="rotate">!</span>')

  return paragraph
}