import React, { useState, useEffect } from 'react'
import Background from '../../baseComponents/BackgroundImage'
import Header from '../../baseComponents/Header'
import { langScheme } from '../../utils/langResources'
import Form from './components/Form'
import FloatingObjects from '../../baseComponents/FloatingObjects'
import { connect } from 'react-redux'
import AnimatedBackground from '../../baseComponents/AnimatedBackground'
import PrivacyNotification from '../../baseComponents/PrivacyNotification'
import BoxContainer from '../../baseComponents/BoxContainer'
import { accents } from '../../utils/accents'
import axios from 'axios'
import './Appointment.css'

const Appointment = ({ initialized }) => {

  const texts = langScheme('appointment')
    , [dates, setDates] = useState([])
    , [success, setSuccess] = useState(false)
    , [error, setError] = useState(null)
  const getAppointments = async () => {
    try {
      let res = await axios({
        url: 'https://us-central1-rat-attack-aed68.cloudfunctions.net/getEventDates',
        method: 'post',
        headers: { 'token': 'tfLdV5azziFCNJGqzooY', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'Content-Type' }
      })
      let items = res.data.map(item => {
        let date = item.id.split(':')[0]
        date = date.split('_')
        date = new Date(date[2], date[1] - 1, date[0])
        return { ...item, date }
      }).sort((a, b) => a.date - b.date)
      setDates(items)
    } catch (error) {
      setError(texts('fetch_error'))
    }
  }

  const onSuccess = () => setSuccess(true)

  useEffect(() => {
    getAppointments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized])


  const onStartTransition = () => {
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  if (error) return <Background>
    <AnimatedBackground />
    <div id='appointment'>
      <Header onStartTransition={onStartTransition} time={2400} />
      <div className='container'>
        <div className='content'>
          <h1 className='title'>{texts('error_text')}</h1>
        </div>
      </div>
    </div>
  </Background>

  if (success) return <Background>
    <AnimatedBackground />
    <div id='appointment'>
      <Header onStartTransition={onStartTransition} time={2400} />
      <div className='container'>
        <div className='content'>
          <h1 className='title'>{texts('success_text')}</h1>
          <h1 className='title'>{texts('success_text_2')}</h1>
        </div>
      </div>
    </div>
  </Background>

  return <Background>
    <AnimatedBackground />
    <div id='appointment'>
      {
        isModalVisible
          ? <div className='modal-privacy'>
            <BoxContainer>
              <PrivacyNotification onClose={() => setIsModalVisible(false)} />
            </BoxContainer>
          </div>
          : null
      }
      <Header onStartTransition={onStartTransition} time={2400} />
      <div className='container'>
        <FloatingObjects />
        <div className='content'>
          <h1 className='title accents' dangerouslySetInnerHTML={{ __html: accents(texts('title')) }}></h1>

          <p className='event_date center font_noway'>Exposición presencial del 14 de septiembre al 17 de octubre</p>

          {
            texts('description').map((item, index) => {
              if (item.type === 'paragraph') return <p key={index} className='description'>{item.text}</p>
              else return <ul key={index} className='list'>
                {item.items.map((text, i) => <li key={i}><p className='description'>{text}</p></li>)}
              </ul>
            })
          }
          <h3 className="select_date_title">Selecciona una fecha</h3>
          <p className='description center'>Si no puedes visitar Rat Attack en alguno de estos horarios y fechas escríbenos un comentario o mándanos correo a info@somamexico.org</p>
          {
            dates.length
              ? <Form dates={dates} onSuccess={onSuccess} setIsModalVisible={setIsModalVisible} />
              : null
          }
        </div>
      </div>
    </div>
  </Background >
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(Appointment)