import { useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'

const usePortal = id => {
  const rootElemRef = useRef(document.createElement('div'))
  useEffect(() => {
    const parentElem = document.querySelector(`#${id}`)

    parentElem.appendChild(rootElemRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => { rootElemRef.current.remove() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return rootElemRef.current
}

const Modal = ({ children }) => {
  const target = usePortal('modal')
  return ReactDOM.createPortal(children, target)
}

export default Modal
