import React, { useEffect } from 'react'
import Background from '../../baseComponents/BackgroundImage'
import Header from '../../baseComponents/Header'
import AnimatedBackground from '../../baseComponents/AnimatedBackground'
import { connect } from 'react-redux'
import CirclePicture from '../../baseComponents/CirclePicture'
import { getEpisodes } from '../../services/episodes'
import { getUrl } from '../../utils/urls'
import { loaderTransition } from '../../utils/transitions'
import { langScheme } from '../../utils/langResources'
import FloatingObjects from '../../baseComponents/FloatingObjects'
import { setLang } from '../../redux/actions/appState'
import { accents } from '../../utils/accents'

import './Episodes.css'

const Episodes = (props) => {

  const texts = langScheme('episodes')
    , { lang } = props.appState

  const episodes = getEpisodes(true)

  const texts_lang = langScheme('header')

  const setEpisode = id => {
    loaderTransition(onStartTransition)
    setTimeout(() => {
      props.history.push(`${getUrl('episode')}/${id}`)
    }, 2400)
  }

  const onDonwload = () => {
    window.open(texts('download_text').url, '_blank')
  }

  const onStartTransition = () => {
  }

  const onSetLang = () => {
    props.dispatch(setLang(lang === 'es' ? 'en' : 'es'))
  }

  useEffect(() => {
    return () => { setLang('es') }
  }, [])

  return <Background>
    <AnimatedBackground />
    <div id='episodes'>
      <Header nStartTransition={onStartTransition} time={2400} />
      <div className='container'>
        <FloatingObjects />
        <div className='description-container'>
          <div id="lang_selector">
            <span onClick={onSetLang}>{texts_lang('lang')}</span>
          </div>
          <h1 className='title artist accents' dangerouslySetInnerHTML={{ __html: accents(texts('title')) }} >
          </h1>
          <p className='description' dangerouslySetInnerHTML={{ __html: texts('description') }} />
          <p className='description author'>
            {texts('author')}
          </p>
          <p className='description link accents' dangerouslySetInnerHTML={{ __html: accents(texts('download_text').text) }} onClick={onDonwload} />
        </div>
        <div className='episode-list'>
          {
            episodes.map(episode => <div onClick={() => setEpisode(episode.id)} key={episode.id} className='episode-container'>
              <CirclePicture overflowHidden={episode.overflowHidden} image={episode.profilePic} alt={episode.artist} />
              <h3 className='artist accents' dangerouslySetInnerHTML={{ __html: accents(episode.artist) }}></h3>
              <p className='title'>{episode[`short_title_${lang}`]}</p>
            </div>)
          }
        </div>
      </div>
    </div>
  </Background >
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(Episodes)
