import React from 'react'
import { getEpisodes } from '../services/episodes'
import { langScheme } from '../utils/langResources'
import { connect } from 'react-redux'
import { accents } from '../utils/accents'
import './Ticker.css'

const colors = [
  '#50EB36',
  '#FD52DF',
  '#E0F383',
  '#00effc',
  '#fe0205',
  '#14fc75',
  '#8529cc',

  '#50EB36',
  '#FD52DF',
  '#E0F383',
  '#00effc',
  '#fe0205',
]

const Ticker = props => {
  const episodes = getEpisodes()
    , texts = langScheme('home')
    , { lang } = props.appState

  return <footer id='banner' className='ticker'>
    <div className="ticker-wrap">
      <div className="ticker-move">
        {
          episodes.map((episode, index) => <div key={episode.id} style={{ color: colors[index] }} className='ticker-item episode accents' dangerouslySetInnerHTML={{ __html: accents(`${texts('episode')} ${index + 1}: ${episode[`short_title_${lang}`]}      `) }} ></div>)
        }
        {
          episodes.map((episode, index) => <div key={episode.id} style={{ color: colors[index] }} className='ticker-item episode accents' dangerouslySetInnerHTML={{ __html: accents(`${texts('episode')} ${index + 1}: ${episode[`short_title_${lang}`]}      `) }} ></div>)
        }
        {
          episodes.map((episode, index) => <div key={episode.id} style={{ color: colors[index] }} className='ticker-item episode accents' dangerouslySetInnerHTML={{ __html: accents(`${texts('episode')} ${index + 1}: ${episode[`short_title_${lang}`]}      `) }} ></div>)
        }
        {
          episodes.map((episode, index) => <div key={episode.id} style={{ color: colors[index] }} className='ticker-item episode accents' dangerouslySetInnerHTML={{ __html: accents(`${texts('episode')} ${index + 1}: ${episode[`short_title_${lang}`]}      `) }} ></div>)
        }
      </div>
    </div>
  </footer>
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(Ticker)