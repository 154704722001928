import React from 'react'

import scissors from '../assets/images/scissors.png'
import pencil from '../assets/images/cranyon_02.png'

import './FloatingObjects.css'

const FloatingObjects = props => {
  return <div id="floating_objects_container">
    <div id="floating_scissors" className="floating_object">
      <img src={scissors} alt='scissors' />
    </div>
    <div id="floating_pencil" className="floating_object">
      <img src={pencil} alt='pencil' />
    </div>
  </div>

}

export default FloatingObjects