import React, { useEffect } from 'react'

import './AnimatedBackground.css'

const AnimatedBackground = pros => {

  const onMouseMove = (e) => {
    let posX = -(e.clientX - window.outerWidth * 0.5) * 0.1
      , posY = -(e.clientY - window.outerHeight * 0.5) * 0.1

    // if (posY > 100) posY = 100
    document.getElementById("main_bg").style.transform = "translateX(" + posX + "px) translateY(" + posY + "px)"
  }

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove)

    return () => {
      window.removeEventListener('mousemove', onMouseMove)
    }
  }, [])

  return <div id="main_bg_container">
    <div id="main_bg"></div>
  </div>
}

export default AnimatedBackground