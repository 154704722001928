import React from 'react'
import Header from './Header'
import FloatingObjects from './FloatingObjects'
import { langScheme } from '../utils/langResources'
import soma_logo from '../assets/images/SOMA.png'
import sponsor_tajo from '../assets/images/sponsors/tajo.png'
import sponsor_axa from '../assets/images/sponsors/axa.png'
import sponsor_aura from '../assets/images/sponsors/aura.png'
import sponsor_soma from '../assets/images/sponsors/soma.png'
import sponsor_efiartes from '../assets/images/sponsors/efiartes.png'

import './PrivacyNotification.css'

const PrivacyNotification = props => {
  const texts = langScheme('privacy')
    , onStartTransition = () => {
    }
  return <div id="semblance">
    {
      typeof props.onClose === 'function'
        ? <div style={{ padding: '1vw 2vw', display: 'flex', justifyContent: 'flex-end' }}>
          <div className="close-btn link">
            <span onClick={props.onClose}>Cerrar</span>
          </div>
        </div>
        : <Header onStartTransition={onStartTransition} time={2400} />
    }
    <div className='container'>
      <FloatingObjects />
      <div className='content'>
        <div className='description-container'>
          <div id="soma_logo" >
            <img src={soma_logo} alt="SOMA logotipo" />
          </div>
          {
            texts('text').map((block, index) => {
              return <React.Fragment key={index}>
                <h3 className='title-text small-title'>{block.title}</h3>
                {
                  block.sub.map((paragraph, subIndex) => {
                    return <React.Fragment key={subIndex} >
                      <p className='description small-text' ><b>{paragraph.title}</b></p>
                      <p className='description small-text'>{paragraph.text}</p>
                    </React.Fragment>
                  })
                }
              </React.Fragment>
            })
          }
        </div>
        <div id="sponsors_container">
          <img src={sponsor_tajo} alt="Tajo" />
          <img src={sponsor_axa} alt="Axa" />
          <img src={sponsor_aura} alt="AURA" />
          <img src={sponsor_soma} alt="SOMA" />
          <img src={sponsor_efiartes} alt="Efiartes" />
          <h4 className="title">{texts('sponsor_text')}</h4>
        </div>
      </div>
    </div>
  </div>

}

export default PrivacyNotification