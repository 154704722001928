const episodes = {
  'oswaldo_aranda': {
    youtube_video: 'Z9drjh4Xewc',
    prev_episode: 'martin_bernstein',
    next_episode: 'samuel_nicolle',
    index: 8,
    profilePic: require('../assets/images/oswaldo_aranda/oswaldo_aranda_rata.png'),
    'title_es': 'SSSS / Secondary Security Screening Selection',
    'title_en': 'SSSS / Secondary Security Screening Selection',
    'short_title_es': 'SSSS / Secondary Security Screening Selection',
    'short_title_en': 'SSSS / Secondary Security Screening Selection',
    artist: 'Oswaldo Aranda',
    'project_desc_es': [
      {
        title: 'Episodio 08: TOPOGRAFÍAS ANECDÓTICAS', text: `En el lapso de septiembre de 2017 a enero de 2018, nuestro conductor se empleó como
        agente de seguridad de pasajeros en el Aeropuerto Internacional de la ciudad de
        Chihuahua. En esos meses, recolectó pequeños objetos extraviados por los pasajeros.
        ¿Cuáles son las historias detrás de estas reliquias? ¿Por qué importan los objetos que nos
        rodean y la forma en que estos viajan? En línea con las investigaciones de Una
        topografía anecdótica del azar, donde Daniel Spoerri narra los sucesos e historias que
        llevaron a una serie de objetos a reunirse en una mesa de su casa, y reelaborando las
        preguntas de Igor Kopytoff sobre los indicadores culturales que conforman la biografía
        de las cosas, este episodio nos invita a hacer una colección crítica personal. Cada objeto
        devela sus relaciones sociales de producción así como sus políticas de valor y conexiones
        
        afectivas. Crea tu propia colección y sorpréndete con la historia que se esconde detrás de
        cada uno de sus elementos.`
      }
    ],
    'project_desc_en': [
      {
        title: 'Episodio 08: TOPOGRAFÍAS ANECDÓTICAS', text: `En el lapso de septiembre de 2017 a enero de 2018, nuestro conductor se empleó como
        agente de seguridad de pasajeros en el Aeropuerto Internacional de la ciudad de
        Chihuahua. En esos meses, recolectó pequeños objetos extraviados por los pasajeros.
        ¿Cuáles son las historias detrás de estas reliquias? ¿Por qué importan los objetos que nos
        rodean y la forma en que estos viajan? En línea con las investigaciones de Una
        topografía anecdótica del azar, donde Daniel Spoerri narra los sucesos e historias que
        llevaron a una serie de objetos a reunirse en una mesa de su casa, y reelaborando las
        preguntas de Igor Kopytoff sobre los indicadores culturales que conforman la biografía
        de las cosas, este episodio nos invita a hacer una colección crítica personal. Cada objeto
        devela sus relaciones sociales de producción así como sus políticas de valor y conexiones
        
        afectivas. Crea tu propia colección y sorpréndete con la historia que se esconde detrás de
        cada uno de sus elementos.`
      }
    ],
    media: {
      images: [
        require('../assets/images/oswaldo_aranda/oswaldo_aranda_1.jpg'),
        require('../assets/images/oswaldo_aranda/oswaldo_aranda_2.jpg'),
        require('../assets/images/oswaldo_aranda/oswaldo_aranda_3.jpg'),
        require('../assets/images/oswaldo_aranda/oswaldo_aranda_4.jpg'),
        require('../assets/images/oswaldo_aranda/oswaldo_aranda_5.jpg'),
        require('../assets/images/oswaldo_aranda/oswaldo_aranda_6.jpg'),
        require('../assets/images/oswaldo_aranda/oswaldo_aranda_7.jpg'),
        require('../assets/images/oswaldo_aranda/oswaldo_aranda_8.jpg'),
        require('../assets/images/oswaldo_aranda/oswaldo_aranda_9.jpg'),
        require('../assets/images/oswaldo_aranda/oswaldo_aranda_10.jpg'),
        require('../assets/images/oswaldo_aranda/oswaldo_aranda_11.jpg'),
      ],
      videos: [],
    },
    'bio_es': [
      {
        title: '', text: `Oswaldo Aranda (México, 1988) busca desarrollar su práctica artística como un diálogo entre el entorno social y la exploración del Yo artístico individual. Le interesa representar su postura artística ante diversas coyunturas y contextos de forma autocrítica, dejando entrever lo que se entiende por “sentido común”, para aludir a memorias locales, situaciones políticas, institucionales y temas de poder social en relación a la práctica artística contemporánea.`
      },
    ],
    'bio_en': [
      {
        title: '', text: `Oswaldo Aranda (México, 1988) busca desarrollar su práctica artística como un diálogo entre el entorno social y la exploración del Yo artístico individual. Le interesa representar su postura artística ante diversas coyunturas y contextos de forma autocrítica, dejando entrever lo que se entiende por “sentido común”, para aludir a memorias locales, situaciones políticas, institucionales y temas de poder social en relación a la práctica artística contemporánea.`
      },
    ],
  },
  'martin_bernstein': {
    youtube_video: 'QRFuAYAVbLM',
    prev_episode: 'marek_wolfryd',
    next_episode: 'oswaldo_aranda',
    index: 7,
    profilePic: require('../assets/images/martin_bernstein/martin_bernstein_rata.png'),
    'title_es': '大 - 暖 - 令人印象深刻 (Great - Warm - Impressively)',
    'title_en': '大 - 暖 - 令人印象深刻 (Great - Warm - Impressively)',
    'short_title_es': '大 - 暖 - 令人印象深刻 (Great - Warm - Impressively)',
    'short_title_en': '大 - 暖 - 令人印象深刻 (Great - Warm - Impressively)',
    artist: 'Martín Bernstein',
    'project_desc_es': [
      {
        title: 'Episodio 07: MÁQUINA ANTÍGENA', text: `Los anticuerpos, tal como nos enseñaron en las clases de biología, son las moléculas
        encargadas de identificar y neutralizar elementos extraños, ajenos o tóxicos (conocidos
        como antígenos) en nuestro cuerpo. Sin antígenos no hay anticuerpos; sin antígenos no
        sería posible conseguir una respuesta inmunitaria y curarnos. Ahora, pensemos esta
        relación entre antígeno, anticuerpo y respuesta inmunitaria en relación con el capitalismo.
        ¿Cómo podríamos generar anticuerpos ante sus estrategias malignas? ¿Es posible?
        Nuestro conductor propone la creación de una máquina de representaciones estrafalarias
        de los productos de este sistema económico, una máquina de antígenos. ¿Para qué? Para
        llevar los efectos alienantes de la tecnología hasta sus últimas consecuencias y obligarnos
        a generar los anticuerpos necesarios. Tal como declara el manifiesto aceleracionista:
        “Sólo una política prometeica en la que se ostente un dominio absoluto de la idiosincrasia
        de la sociedad y su entorno será capaz de abordar los problemas globales o lograr una
        victoria sobre el capital.” Convierte la desesperanza dominante en antígenos, y descubre
        un nuevo orden mundial.`
      }
    ],
    'project_desc_en': [
      {
        title: 'Episodio 07: MÁQUINA ANTÍGENA', text: `Los anticuerpos, tal como nos enseñaron en las clases de biología, son las moléculas
        encargadas de identificar y neutralizar elementos extraños, ajenos o tóxicos (conocidos
        como antígenos) en nuestro cuerpo. Sin antígenos no hay anticuerpos; sin antígenos no
        sería posible conseguir una respuesta inmunitaria y curarnos. Ahora, pensemos esta
        relación entre antígeno, anticuerpo y respuesta inmunitaria en relación con el capitalismo.
        ¿Cómo podríamos generar anticuerpos ante sus estrategias malignas? ¿Es posible?
        Nuestro conductor propone la creación de una máquina de representaciones estrafalarias
        de los productos de este sistema económico, una máquina de antígenos. ¿Para qué? Para
        llevar los efectos alienantes de la tecnología hasta sus últimas consecuencias y obligarnos
        a generar los anticuerpos necesarios. Tal como declara el manifiesto aceleracionista:
        “Sólo una política prometeica en la que se ostente un dominio absoluto de la idiosincrasia
        de la sociedad y su entorno será capaz de abordar los problemas globales o lograr una
        victoria sobre el capital.” Convierte la desesperanza dominante en antígenos, y descubre
        un nuevo orden mundial.`
      }
    ],
    media: {
      images: [
        require('../assets/images/martin_bernstein/martin_bernstein_1.png'),
        require('../assets/images/martin_bernstein/martin_bernstein_2.png'),
        require('../assets/images/martin_bernstein/martin_bernstein_3.png'),
        require('../assets/images/martin_bernstein/martin_bernstein_4.png'),
        require('../assets/images/martin_bernstein/martin_bernstein_5.jpg'),
        require('../assets/images/martin_bernstein/martin_bernstein_6.png'),
        require('../assets/images/martin_bernstein/martin_bernstein_7.jpg'),
        require('../assets/images/martin_bernstein/martin_bernstein_8.jpg'),
        require('../assets/images/martin_bernstein/martin_bernstein_9.jpg'),
        require('../assets/images/martin_bernstein/martin_bernstein_10.jpg'),
        require('../assets/images/martin_bernstein/martin_bernstein_11.jpg'),
        require('../assets/images/martin_bernstein/martin_bernstein_12.jpg'),
        require('../assets/images/martin_bernstein/martin_bernstein_13.jpg'),
      ],
      videos: [],
    },
    'bio_es': [
      {
        title: '', text: `Martin Bernstein (Argentina, 1986) comienza ha desarrollar su obra desde el dibujo, virando posteriormente a distintos soportes como el video, la animación, la fotografía, la instalación y el webart, valiéndose frecuentemente del ready-made y la intervención como modus operandi. Su trabajo aborda las temáticas del marketing en el imperialismo cultural y el consumo pasivo de propaganda política, los memes como medio de militancia y la estética del terrorismo como estrategia comunicativa. Paralelamente se ha desempeñado en disciplinas menos artísticas como la historieta o el arbitraje de boxeo amateur.
        En Argentina ha expuesto en el Centro Cultural Kirchner, el Centro Cultural Recoleta, Galería Ruth Benzacar, el Museo Nacional de Bellas Artes, el Museo de Arte Moderno de Buenos Aires, la Universidad Torcuato Di Tella, entre otros. También cuenta con exposiciones en Chile, Perú, Colombia y México.`
      },
    ],
    'bio_en': [
      {
        title: '', text: `Martin Bernstein (Argentina, 1986) comienza ha desarrollar su obra desde el dibujo, virando posteriormente a distintos soportes como el video, la animación, la fotografía, la instalación y el webart, valiéndose frecuentemente del ready-made y la intervención como modus operandi. Su trabajo aborda las temáticas del marketing en el imperialismo cultural y el consumo pasivo de propaganda política, los memes como medio de militancia y la estética del terrorismo como estrategia comunicativa. Paralelamente se ha desempeñado en disciplinas menos artísticas como la historieta o el arbitraje de boxeo amateur.
        En Argentina ha expuesto en el Centro Cultural Kirchner, el Centro Cultural Recoleta, Galería Ruth Benzacar, el Museo Nacional de Bellas Artes, el Museo de Arte Moderno de Buenos Aires, la Universidad Torcuato Di Tella, entre otros. También cuenta con exposiciones en Chile, Perú, Colombia y México.`
      },
    ],
  },
  'wendy_cabrera_rubio': {
    youtube_video: 'J7mpPza2G4U',
    prev_episode: 'valentina_guerrero_marin',
    next_episode: 'perla_ramos',
    index: 11,
    overflowHidden: true,
    profilePic: require('../assets/images/wendy_cabrera_rubio/wendy_cabrera_rubio_rata.png'),
    'title_es': 'But tell me, Donald, have you ever been to Baia?',
    'title_en': 'But tell me, Donald, have you ever been to Baia?',
    'short_title_es': 'But tell me, Donald, have you ever been to Baia?',
    'short_title_en': 'But tell me, Donald, have you ever been to Baia?',
    artist: 'Wendy Cabrera Rubio',
    'project_desc_es': [
      {
        title: 'Episodio 11: TÍTERES ARTESANALES', text: `Tres grandes amigos son los protagonistas de este episodio. José Carioca (el simpático
        loro brasileño creado por Disney), Zé Carioca (la interpretación de José Carioca en las
        tiras cómicas brasileñas) y Pancho Pistolas (el alegre gallo tapatío, también creado por
        Disney) discutirán y recorrerán la sección de artesanías mexicanas de la Primera Bienal
        Latinoamericana de Sao Paulo de 1978. ¿Es esta Bienal una exposición o un parque de
        diversiones? ¿Son estos objetos obras de arte o souvenirs de aeropuerto? ¿Son estos
        personajes expertos en arte o títeres manejados por corporaciones “invisibles” que no
        hacen más que replicar discursos folclorizantes? ¿Deberíamos seguir defendiendo la
        “identidad latinoamericana” o se trata de un efecto más de la homogeneización
        colonizadora de las estructuras de los Estados Nación? Pon en práctica tus dotes de
        ventrílocuo y de humorista, ¡conviértete en crítico de arte!`
      }
    ],
    'project_desc_en': [
      {
        title: 'Episodio 11: TÍTERES ARTESANALES', text: `Tres grandes amigos son los protagonistas de este episodio. José Carioca (el simpático
        loro brasileño creado por Disney), Zé Carioca (la interpretación de José Carioca en las
        tiras cómicas brasileñas) y Pancho Pistolas (el alegre gallo tapatío, también creado por
        Disney) discutirán y recorrerán la sección de artesanías mexicanas de la Primera Bienal
        Latinoamericana de Sao Paulo de 1978. ¿Es esta Bienal una exposición o un parque de
        diversiones? ¿Son estos objetos obras de arte o souvenirs de aeropuerto? ¿Son estos
        personajes expertos en arte o títeres manejados por corporaciones “invisibles” que no
        hacen más que replicar discursos folclorizantes? ¿Deberíamos seguir defendiendo la
        “identidad latinoamericana” o se trata de un efecto más de la homogeneización
        colonizadora de las estructuras de los Estados Nación? Pon en práctica tus dotes de
        ventrílocuo y de humorista, ¡conviértete en crítico de arte!`
      }
    ],
    media: {
      images: [
        require('../assets/images/wendy_cabrera_rubio/wendy_cabrera_rubio_1.jpg'),
        require('../assets/images/wendy_cabrera_rubio/wendy_cabrera_rubio_2.jpg'),
        require('../assets/images/wendy_cabrera_rubio/wendy_cabrera_rubio_3.jpg'),
        require('../assets/images/wendy_cabrera_rubio/wendy_cabrera_rubio_4.jpg'),
        require('../assets/images/wendy_cabrera_rubio/wendy_cabrera_rubio_5.jpg'),
        require('../assets/images/wendy_cabrera_rubio/wendy_cabrera_rubio_6.jpg'),
        require('../assets/images/wendy_cabrera_rubio/wendy_cabrera_rubio_7.jpg'),
        require('../assets/images/wendy_cabrera_rubio/wendy_cabrera_rubio_8.jpg'),
        require('../assets/images/wendy_cabrera_rubio/wendy_cabrera_rubio_9.jpg'),
        require('../assets/images/wendy_cabrera_rubio/wendy_cabrera_rubio_10.jpg'),
        require('../assets/images/wendy_cabrera_rubio/wendy_cabrera_rubio_11.jpg'),
        require('../assets/images/wendy_cabrera_rubio/wendy_cabrera_rubio_12.jpg'),
        require('../assets/images/wendy_cabrera_rubio/wendy_cabrera_rubio_13.jpg'),
      ],
      videos: [],
    },
    'bio_es': [
      {
        title: '', text: `Wendy Cabrera Rubio (Ciudad de México, 1993), explora las relaciones entre estética e ideología, a partir de revisiones históricas de los mecanismos de producción y distribución de las imágenes. El performance, los textiles y las marionetas son medios recurrentes en su producción. Le interesa el impacto de las ciencias aplicadas en el imaginario popular y el papel del arte dentro de la sociedad.`
      },
      {
        title: '', text: `Cursó sus estudios de licenciatura en la ENPEG “La Esmeralda” y posteriormente ingresó al Programa Educativo SOMA. Su obra se ha presentado colectivamente en el Museo Universitario de Ciencias y Artes, Laboratorio Arte Alameda, Galería Curro, entre otros. E individualmente destacan: Kurimanzutto, y galería Nordenhake. Wendy forma parte del proyecto del Museo Comunitario y Club de lectura de Sierra Hermosa Zacatecas, también realizo labores de gestión como miembro de LADRÓN galería y es miembro fundador de rivera galería.`
      },
    ],
    'bio_en': [
      {
        title: '', text: `Wendy Cabrera Rubio (Ciudad de México, 1993), explora las relaciones entre estética e ideología, a partir de revisiones históricas de los mecanismos de producción y distribución de las imágenes. El performance, los textiles y las marionetas son medios recurrentes en su producción. Le interesa el impacto de las ciencias aplicadas en el imaginario popular y el papel del arte dentro de la sociedad.`
      },
      {
        title: '', text: `Cursó sus estudios de licenciatura en la ENPEG “La Esmeralda” y posteriormente ingresó al Programa Educativo SOMA. Su obra se ha presentado colectivamente en el Museo Universitario de Ciencias y Artes, Laboratorio Arte Alameda, Galería Curro, entre otros. E individualmente destacan: Kurimanzutto, y galería Nordenhake. Wendy forma parte del proyecto del Museo Comunitario y Club de lectura de Sierra Hermosa Zacatecas, también realizo labores de gestión como miembro de LADRÓN galería y es miembro fundador de rivera galería.`
      },
    ],
  },
  'clemente_castor': {
    youtube_video: '1l5bQEI93II',
    prev_episode: 'angela_ferrari',
    next_episode: 'israel_urmeer',
    index: 3,
    profilePic: require('../assets/images/clemente_castor/clemente_castor_rata.png'),
    'title_es': 'Primavera (Aceleredor de imágenes)',
    'title_en': 'Primavera (Aceleredor de imágenes)',
    'short_title_es': 'Primavera (Aceleredor de imágenes)',
    'short_title_en': 'Primavera (Aceleredor de imágenes)',
    artist: 'Clemente Castor',
    'project_desc_es': [
      {
        title: 'Episodio 3 ISOMORFISMO:', text: `Propongamos que nuestro futuro tiene asignada la letra (a). Y también
        supongamos que (a) es una catástrofe. Ahora imaginemos que (a) es,
        también, una pared. ¿Qué podría haber detrás de ella? La pregunta por
        
        lo que viene después del final es cada vez más pertinente. Consider-
        emos ahora que hay una realidad virtual asomándose a través de esa
        
        pared y asignémosle la letra (b). Aceptemos que el ser que vive ahí no
        es humanx y que no podremos establecer (todavía) una comunicación,
        solo mirarlx a través. Este episodio es un horizonte de sucesos entre
        (a) y (b). Con algunas imágenes y una lupa, entenderemos qué es el
        isomorfismo y cómo la relación entre dos estructuras, en este caso (a)
        y (b), puede ser reversible. En otras palabras, comprobaremos que, en
        
        algunos casos, (a) y (b) pueden transformarse el uno en el otro. Descu-bre tus futuros posibles, no tienes que elegir solo uno, ¡son isomórfi-cos!`
      }
    ],
    'project_desc_en': [
      {
        title: 'Episodio 3 ISOMORFISMO:', text: `Propongamos que nuestro futuro tiene asignada la letra (a). Y también
        supongamos que (a) es una catástrofe. Ahora imaginemos que (a) es,
        también, una pared. ¿Qué podría haber detrás de ella? La pregunta por
        
        lo que viene después del final es cada vez más pertinente. Consider-
        emos ahora que hay una realidad virtual asomándose a través de esa
        
        pared y asignémosle la letra (b). Aceptemos que el ser que vive ahí no
        es humanx y que no podremos establecer (todavía) una comunicación,
        solo mirarlx a través. Este episodio es un horizonte de sucesos entre
        (a) y (b). Con algunas imágenes y una lupa, entenderemos qué es el
        isomorfismo y cómo la relación entre dos estructuras, en este caso (a)
        y (b), puede ser reversible. En otras palabras, comprobaremos que, en
        
        algunos casos, (a) y (b) pueden transformarse el uno en el otro. Descu-bre tus futuros posibles, no tienes que elegir solo uno, ¡son isomórfi-cos!`
      }
    ],
    media: {
      images: [
        require('../assets/images/clemente_castor/clemente_castor_1.jpg'),
        require('../assets/images/clemente_castor/clemente_castor_2.jpg'),
        require('../assets/images/clemente_castor/clemente_castor_3.jpg'),
        require('../assets/images/clemente_castor/clemente_castor_4.jpg'),
        require('../assets/images/clemente_castor/clemente_castor_5.jpg'),
        require('../assets/images/clemente_castor/clemente_castor_6.jpg'),
        require('../assets/images/clemente_castor/clemente_castor_7.jpg'),
        require('../assets/images/clemente_castor/clemente_castor_8.jpg'),
        require('../assets/images/clemente_castor/clemente_castor_9.jpg'),
        require('../assets/images/clemente_castor/clemente_castor_10.jpg')
      ],
      videos: [],
    },
    'bio_es': [
      {
        title: '', text: `Clemente Castor (Ciudad de México, 1994) utiliza principalmente los medios audiovisuales y la instalación. Le interesa la imagen desde su potencial político y cómo se formulan sus sistemas de representación y consumo. Entiende la ciencia ficción como el género de la crisis, como una narrativa que permite especular otro tipo de escenarios, haciendo una relación entre lo natural y lo artificial, entre la idea de la tecnología como prótesis, contenedor y espacio virtual. 
        Cursó sus estudios en literatura latinoamericana y recibió el apoyo del FONCA para estudiar cine en la Sarajevo Film Academy en Bosnia Herzegovina. Su largometraje “Príncipe de Paz” recibió el premio a la mejor película mexicana en FICUNAM 2019. Ha presentado su trabajo en el Lincoln Center de NY, Viennale, FidMarseille, Biquini Wax EPS, Museo de la Ciudad de Querétaro, Baxter St. N.Y, Queens L.A. entre otros.`
      },
    ],
    'bio_en': [
      {
        title: '', text: `Clemente Castor (Ciudad de México, 1994) utiliza principalmente los medios audiovisuales y la instalación. Le interesa la imagen desde su potencial político y cómo se formulan sus sistemas de representación y consumo. Entiende la ciencia ficción como el género de la crisis, como una narrativa que permite especular otro tipo de escenarios, haciendo una relación entre lo natural y lo artificial, entre la idea de la tecnología como prótesis, contenedor y espacio virtual. 
        Cursó sus estudios en literatura latinoamericana y recibió el apoyo del FONCA para estudiar cine en la Sarajevo Film Academy en Bosnia Herzegovina. Su largometraje “Príncipe de Paz” recibió el premio a la mejor película mexicana en FICUNAM 2019. Ha presentado su trabajo en el Lincoln Center de NY, Viennale, FidMarseille, Biquini Wax EPS, Museo de la Ciudad de Querétaro, Baxter St. N.Y, Queens L.A. entre otros.`
      },
    ],
  },
  'angela_ferrari': {
    youtube_video: 'cSq1O4ru1LM',
    prev_episode: 'perla_ramos',
    next_episode: 'clemente_castor',
    index: 2,
    profilePic: require('../assets/images/angela_ferrari/angela_ferrari_rata.png'),
    'title_es': 'Un paisaje',
    'title_en': 'Un paisaje',
    'short_title_es': 'Un paisaje',
    'short_title_en': 'Un paisaje',
    artist: 'Ángela Ferrari',
    'project_desc_es': [
      {
        title: 'Episodio 02: CORTINAS SIN PATRÓN', text: `Con Ángela Ferrari ¿Qué pasa cuando corremos la cortina del romanticismo? ¿Hay algo del otro lado? De la mano de nuestra anfitriona, en este episodio explorarás la experiencia sublime como aquella que emerge exactamente a medio camino entre lo bello y lo grotesco. Atravesarás un paisaje, digamos bucólico: el amor romántico y la sexualidad normada, para llegar a otro, digamos inexplorado: la orografía de nuestra cavidad bucal con sus mucosas y bacterias, con sus dientes putrefactos y su fisiología de la deglución. El asco, la vergüenza, el miedo y la idealización son construcciones sociopolíticas que nos limitan. Tal como lo propone el manifiesto Xenofeminista, este episodio es “una ambición incipiente para construir un nuevo lenguaje para la política sexual”. Imagina una sexualidad sin patrones! y descubre tus verdaderos deseos e inclinaciones!`
      },
    ],
    'project_desc_en': [
      {
        title: 'Episodio 02: CORTINAS SIN PATRÓN', text: `Con Ángela Ferrari ¿Qué pasa cuando corremos la cortina del romanticismo? ¿Hay algo del otro lado? De la mano de nuestra anfitriona, en este episodio explorarás la experiencia sublime como aquella que emerge exactamente a medio camino entre lo bello y lo grotesco. Atravesarás un paisaje, digamos bucólico: el amor romántico y la sexualidad normada, para llegar a otro, digamos inexplorado: la orografía de nuestra cavidad bucal con sus mucosas y bacterias, con sus dientes putrefactos y su fisiología de la deglución. El asco, la vergüenza, el miedo y la idealización son construcciones sociopolíticas que nos limitan. Tal como lo propone el manifiesto Xenofeminista, este episodio es “una ambición incipiente para construir un nuevo lenguaje para la política sexual”. Imagina una sexualidad sin patrones! y descubre tus verdaderos deseos e inclinaciones!`
      }
    ],
    'links_es': [
      {
        texts: [
          'Serie de dibujos para el libro "Generación Espontánea" de Ángela Ferrari. Selección 10/30.',
          'Grafito sobre papel acuarelable.',
          'Medidas variables.',
          '2017-actualidad.'
        ],
        textLink: 'Descargar portafolio completo aquí.',
        link: require('../assets/pdf/angela_ferrari/Portfolio_Angela_Ferrari.pdf')
      }
    ],
    'link_en': [
      {
        texts: [
          'Serie de dibujos para el libro "Generación Espontánea" de Ángela Ferrari. Selección 10/30.',
          'Grafito sobre papel acuarelable.',
          'Medidas variables.',
          '2017-actualidad.'
        ],
        textLink: 'Descargar portafolio completo aquí.',
        link: require('../assets/pdf/angela_ferrari/Portfolio_Angela_Ferrari.pdf')
      }
    ],
    media: {
      images: [
        require('../assets/images/angela_ferrari/Angela_Ferrari_01.jpg'),
        require('../assets/images/angela_ferrari/Angela_Ferrari_02.jpg'),
        require('../assets/images/angela_ferrari/Angela_Ferrari_03.jpg'),
        require('../assets/images/angela_ferrari/Angela_Ferrari_04.jpg'),
        require('../assets/images/angela_ferrari/Angela_Ferrari_05.jpg'),
        require('../assets/images/angela_ferrari/Angela_Ferrari_06.jpg'),
        require('../assets/images/angela_ferrari/Angela_Ferrari_08.jpg'),
        require('../assets/images/angela_ferrari/Angela_Ferrari_09.jpg'),
        require('../assets/images/angela_ferrari/Angela_Ferrari_10.jpg'),
        require('../assets/images/angela_ferrari/Angela_Ferrari_11.jpg'),
        require('../assets/images/angela_ferrari/Angela_Ferrari_12.jpg'),
        require('../assets/images/angela_ferrari/Angela_Ferrari_13.jpg'),
        require('../assets/images/angela_ferrari/Angela_Ferrari_14.jpg'),
        require('../assets/images/angela_ferrari/Angela_Ferrari_15.jpg'),
        require('../assets/images/angela_ferrari/Angela_Ferrari_16.jpg'),
      ],
      videos: [],
    },
    'bio_es': [
      {
        title: '', text: `Ángela Ferrari (Argentina, 1990 ) es una artista interesada en visibilizar la tensión que hay entre lo supuesto bello y el abuso de la belleza; y la relación entre las distintas miradas subjetivas sobre esa preciosidad particular. Pretende encarnarla empleando una especie de barroquismo grotesco/pasional como medio para transmitir mensajes. Como resultado, considera que su producción presenta cierto movimiento, una aleación de materialidad diversa que refiere a estéticas más mundanas que sublimes.`
      },
      {
        title: '', text: `Se graduó de la Licenciatura en Pintura en la Universidad Nacional de Arte de Buenos Aires. Tomó cursos de historia del arte, arte contemporáneo argentino, cerámica japonesa, pintura, dibujo, escultura, grabado, etc. Realizó talleres de producción artística a cargo de Florencia Rodrigues Giles, Catalina León, Marcela Sinclair, entre otres; y taller de producción literario con Ricardo Strafacce y Vivian Avenshushan. Escribió e ilustró la novela ficcional A PELAME, publicada por la editorial Notanpüan en Buenos Aires argentina. Escribió también “Generación espontánea” e “Invertebrados”.
        Ganó  las becas de Formación otorgada por el Fondo Nacional de las Artes, del Centro de Investigaciones Artísticas, de Viajes Oxenford, SOMA, entre otras.
        Expuso su trabajo en Ciudad de México y Monterrey (México), en Cali y Pasto (Colombia), en Bs. As. y San Martín de los Andes (Argentina), New Jersey (EEUU) y Berlín (Alemania). Realizó una residencia en Lugar a Dudas en Cali, Colombia.`
      },
    ],
    'bio_en': [
      {
        title: '', text: `Ángela Ferrari (Argentina, 1990 ) es una artista interesada en visibilizar la tensión que hay entre lo supuesto bello y el abuso de la belleza; y la relación entre las distintas miradas subjetivas sobre esa preciosidad particular. Pretende encarnarla empleando una especie de barroquismo grotesco/pasional como medio para transmitir mensajes. Como resultado, considera que su producción presenta cierto movimiento, una aleación de materialidad diversa que refiere a estéticas más mundanas que sublimes.`
      },
      {
        title: '', text: `Se graduó de la Licenciatura en Pintura en la Universidad Nacional de Arte de Buenos Aires. Tomó cursos de historia del arte, arte contemporáneo argentino, cerámica japonesa, pintura, dibujo, escultura, grabado, etc. Realizó talleres de producción artística a cargo de Florencia Rodrigues Giles, Catalina León, Marcela Sinclair, entre otres; y taller de producción literario con Ricardo Strafacce y Vivian Avenshushan. Escribió e ilustró la novela ficcional A PELAME, publicada por la editorial Notanpüan en Buenos Aires argentina. Escribió también “Generación espontánea” e “Invertebrados”.
        Ganó  las becas de Formación otorgada por el Fondo Nacional de las Artes, del Centro de Investigaciones Artísticas, de Viajes Oxenford, SOMA, entre otras.
        Expuso su trabajo en Ciudad de México y Monterrey (México), en Cali y Pasto (Colombia), en Bs. As. y San Martín de los Andes (Argentina), New Jersey (EEUU) y Berlín (Alemania). Realizó una residencia en Lugar a Dudas en Cali, Colombia.`
      },
    ],
  },
  'valentina_guerrero_marin': {
    youtube_video: 'caWLkG7vZnY',
    prev_episode: 'samuel_nicolle',
    next_episode: 'wendy_cabrera_rubio',
    index: 10,
    profilePic: require('../assets/images/valentina_guerrero_marin/valentina_guerrero_marin_rata.png'),
    'title_es': 'A las plantas se les nombra por sus hojas',
    'title_en': 'A las plantas se les nombra por sus hojas',
    'short_title_es': 'A las plantas se les nombra por sus hojas',
    'short_title_en': 'A las plantas se les nombra por sus hojas',
    artist: 'Valentina Guerrero Marin',
    'project_desc_es': [
      {
        title: 'Episodio 10: MALEZA', text: ``
      },
      {
        title: '', text: `El progreso, dice la antropóloga Anna Tsing, “nos obliga a mirar hacia adelante. Pero la vida”, subraya, “está a nuestro alrededor”. Con espuma, hojas de Mala mujer y cemento blanco exploraremos la noción de maleza. Se suele denominar indiscriminadamente “maleza” a las plantas silvestres que crecen en los campos agrícolas, independientemente de cuán nocivas sean para los cultivos. En este episodio aprenderemos a mirar alrededor. A poner atención en la vida que crece entre las grietas. Descubre particularmente a la Mala mujer, un tipo de ortiga que no quiere ser domesticada, ¡una planta medicinal que se asoma en las fisuras! Las ruinas del capitalismo nos miran, nos acechan, nos preguntan ¿qué hemos hecho? Y la Mala mujer se asoma y crece ahí donde parecía imposible imaginar la vida. Reconoce tu devenir planta e identifica tu lugar más allá del macetero.`
      }
    ],
    'project_desc_en': [
      {
        title: 'Episodio 10: MALEZA', text: ``
      },
      {
        title: '', text: `El progreso, dice la antropóloga Anna Tsing, “nos obliga a mirar hacia adelante. Pero la vida”, subraya, “está a nuestro alrededor”. Con espuma, hojas de Mala mujer y cemento blanco exploraremos la noción de maleza. Se suele denominar indiscriminadamente “maleza” a las plantas silvestres que crecen en los campos agrícolas, independientemente de cuán nocivas sean para los cultivos. En este episodio aprenderemos a mirar alrededor. A poner atención en la vida que crece entre las grietas. Descubre particularmente a la Mala mujer, un tipo de ortiga que no quiere ser domesticada, ¡una planta medicinal que se asoma en las fisuras! Las ruinas del capitalismo nos miran, nos acechan, nos preguntan ¿qué hemos hecho? Y la Mala mujer se asoma y crece ahí donde parecía imposible imaginar la vida. Reconoce tu devenir planta e identifica tu lugar más allá del macetero.`
      }
    ],
    media: {
      images: [
        require('../assets/images/valentina_guerrero_marin/valentina_guerrero_marin_1.jpg'),
        require('../assets/images/valentina_guerrero_marin/valentina_guerrero_marin_2.jpg'),
        require('../assets/images/valentina_guerrero_marin/valentina_guerrero_marin_3.jpg'),
        require('../assets/images/valentina_guerrero_marin/valentina_guerrero_marin_4.jpg'),
        require('../assets/images/valentina_guerrero_marin/valentina_guerrero_marin_5.gif'),
        require('../assets/images/valentina_guerrero_marin/valentina_guerrero_marin_6.jpg'),
        require('../assets/images/valentina_guerrero_marin/valentina_guerrero_marin_7.jpg'),
        require('../assets/images/valentina_guerrero_marin/valentina_guerrero_marin_8.jpg'),
        require('../assets/images/valentina_guerrero_marin/valentina_guerrero_marin_9.jpg'),
        require('../assets/images/valentina_guerrero_marin/valentina_guerrero_marin_10.jpg'),
        require('../assets/images/valentina_guerrero_marin/valentina_guerrero_marin_11.jpg'),
        require('../assets/images/valentina_guerrero_marin/valentina_guerrero_marin_12.jpg'),
      ],
      videos: [],
    },
    'bio_es': [
      {
        title: '', text: `Valentina Guerrero Marín (Chile, 1994) utiliza el video y la instalación como medios para representar el límite entre lo que aparenta ser algo y su forma real, o más bien, marcar el momento en el que un cuerpo es absorbido por una idea y cuando esa idea pasa a constituir la realidad misma. En su práctica artística el medio adquiere un carácter dual: un intermedio entre la falsedad y la verdad material, entre el concepto para ser pensado y el objeto para ser contemplado. `
      },
      {
        title: '', text: `Estudió Artes Plásticas en la Pontificia Universidad Católica de Chile y posteriormente ingresó al Programa Educativo SOMA. Ha expuesto colectivamente en el Museo de Artes Visuales de Chile, en la Sala de arte CCU de Chile, en residencia Almendro en México y de forma individual en el Espacio Vilches en Chile.`
      },
    ],
    'bio_en': [
      {
        title: '', text: `Valentina Guerrero Marín (Chile, 1994) utiliza el video y la instalación como medios para representar el límite entre lo que aparenta ser algo y su forma real, o más bien, marcar el momento en el que un cuerpo es absorbido por una idea y cuando esa idea pasa a constituir la realidad misma. En su práctica artística el medio adquiere un carácter dual: un intermedio entre la falsedad y la verdad material, entre el concepto para ser pensado y el objeto para ser contemplado. `
      },
      {
        title: '', text: `Estudió Artes Plásticas en la Pontificia Universidad Católica de Chile y posteriormente ingresó al Programa Educativo SOMA. Ha expuesto colectivamente en el Museo de Artes Visuales de Chile, en la Sala de arte CCU de Chile, en residencia Almendro en México y de forma individual en el Espacio Vilches en Chile.`
      },
    ],
  },
  'josue_mejia': {
    youtube_video: 'mc2VZarjJEQ',
    prev_episode: 'israel_urmeer',
    next_episode: 'marek_wolfryd',
    index: 5,
    profilePic: require('../assets/images/josue_mejia/josue_mejia_rata.png'),
    'title_es': 'Esplendor de la industria automotriz mexicana en tiempos de amistad',
    'title_en': 'Splendor of the Mexican automotive industry in times of friendship',
    'short_title_es': 'Esplendor de la industria automotriz mexicana en tiempos de amistad',
    'short_title_en': 'Splendor of the Mexican automotive industry in times of friendship',
    artist: 'Josué Mejía',
    'project_desc_es': [
      {
        title: 'Episodio 5 AMISTAD EN CADENA:', text: `¿México y Estados Unidos son amigos? Si es así, ¿cómo se ha construido esa amistad? A
       través de material museográfico, maquetas, esculturas y documentos diversos, este
       episodio pone en relación eventos aparentemente distantes (pero casi simultáneos) para
       develar dos caras de esa relación. O, para ser más clarxs: señala una forma de
       adoctrinamiento disfrazada de amistad. Nuestro anfitrión ejemplificará esta relación con
       una fórmula sencilla de importación y exportación ideológica a partir de las conexiones
       entre un mural de Diego Rivera y una planta productora automotriz. Exportación: entre
       1932 y 1933 Rivera pintó en un enorme fresco, dentro del Instituto de Artes de Detroit,
       representando la flamante revolución industrial de esa ciudad desde el punto de vista de
       los obreros. Hizo también un acento en la compañía Ford, que pagó buena parte el
       proyecto. Importación: en septiembre de 1932 se inauguró la primera planta productora
       de automóviles Ford en La Villa (Ciudad de México), dando comienzo a la producción en
       cadena en nuestro país. Esta importación será, sin duda, uno de los antecedentes de lo que
       hoy conocemos como industria maquiladora y sus funestas consecuencias. Aprende y
       aplica la fórmula de la amistad y analiza las relaciones que estableces a tu alrededor.
       ¿Qué importas o qué exportas? Y, sobre todo: ¿á cambio de qué? ¡Aceita tu maquinaria!`
      }
    ],
    'project_desc_en': [
      {
        title: 'Episodio 5 AMISTAD EN CADENA:', text: `¿México y Estados Unidos son amigos? Si es así, ¿cómo se ha construido esa amistad? A
       través de material museográfico, maquetas, esculturas y documentos diversos, este
       episodio pone en relación eventos aparentemente distantes (pero casi simultáneos) para
       develar dos caras de esa relación. O, para ser más clarxs: señala una forma de
       adoctrinamiento disfrazada de amistad. Nuestro anfitrión ejemplificará esta relación con
       una fórmula sencilla de importación y exportación ideológica a partir de las conexiones
       entre un mural de Diego Rivera y una planta productora automotriz. Exportación: entre
       1932 y 1933 Rivera pintó en un enorme fresco, dentro del Instituto de Artes de Detroit,
       representando la flamante revolución industrial de esa ciudad desde el punto de vista de
       los obreros. Hizo también un acento en la compañía Ford, que pagó buena parte el
       proyecto. Importación: en septiembre de 1932 se inauguró la primera planta productora
       de automóviles Ford en La Villa (Ciudad de México), dando comienzo a la producción en
       cadena en nuestro país. Esta importación será, sin duda, uno de los antecedentes de lo que
       hoy conocemos como industria maquiladora y sus funestas consecuencias. Aprende y
       aplica la fórmula de la amistad y analiza las relaciones que estableces a tu alrededor.
       ¿Qué importas o qué exportas? Y, sobre todo: ¿á cambio de qué? ¡Aceita tu maquinaria!`
      }
    ],
    media: {
      images: [
        require('../assets/images/josue_mejia/josue_mejia_1.jpg'),
        require('../assets/images/josue_mejia/josue_mejia_2.jpg'),
        require('../assets/images/josue_mejia/josue_mejia_3.jpg'),
        require('../assets/images/josue_mejia/josue_mejia_4.jpg'),
        require('../assets/images/josue_mejia/josue_mejia_5.jpg'),
        require('../assets/images/josue_mejia/josue_mejia_6.jpg'),
        require('../assets/images/josue_mejia/josue_mejia_7.jpg'),
        require('../assets/images/josue_mejia/josue_mejia_8.jpg'),
        require('../assets/images/josue_mejia/josue_mejia_8.jpg'),
        require('../assets/images/josue_mejia/josue_mejia_9.jpg'),
        require('../assets/images/josue_mejia/josue_mejia_10.jpg'),
        require('../assets/images/josue_mejia/josue_mejia_11.jpg'),
        require('../assets/images/josue_mejia/josue_mejia_12.jpg'),
        require('../assets/images/josue_mejia/josue_mejia_13.jpg'),
      ],
      videos: [],
    },
    'bio_es': [
      {
        title: '', text: `Josué Mejía (Ciudad de México, 1994)
       explora, en su trabajo, las relaciones 
       entre la producción de tecnologías y los 
       procesos políticos y económicos que la hacen 
       posible. `},
      {
        title: '', text: `Partiendo de revisiones históricas de las 
       representaciones que se han realizado de ella, 
       busca construir nuevas narrativas que hagan 
       visibles los procesos de negociación entre 
       tecnologías y políticas donde el arte ha 
       operado como mediador. Para esto hace uso de 
       métodos museográficos relacionados con lo 
       didáctico, procesos de distribución de 
       información y métodos de proyección a 
       partir de escalas.`},
      {
        title: '', text: `Cursó sus estudios de licenciatura en la 
       Facultad de Artes y Diseño de la UNAM y 
       posteriormente ingresó al Programa Educativo 
       SOMA. Fue becario de Jóvenes Creadores 
       FONCA 2017-2018 en la categoría de 
       Medios Alternativos.`}
    ],
    'bio_en': [
      {
        title: '', text: `Josué Mejía (Mexico City, 1994)
        explores, in his work, relationships
        between the production of technologies and
        political and economic processes that make it
        possible.`},
      {
        title: '', text: `Based on historical reviews of the
        representations that have been made of it,
        seeks to build new narratives that make
        visible the negotiation processes between
        technologies and policies where art has
        operated as a mediator. For this it makes use of
        Museographic methods related to
        didactic, distribution processes
        information and projection methods to
        starting from scales.`},
      {
        title: '', text: `He completed his undergraduate studies at the Faculty of Arts and Design of the UNAM and later he entered the Educational Program SOMA. He was a fellow of Young Creators FONCA 2017-2018 in the category of Alternative media.`
      }
    ],
  },
  'samuel_nicolle': {
    youtube_video: 'bBa5yl0_69Y',
    prev_episode: 'oswaldo_aranda',
    next_episode: 'valentina_guerrero_marin',
    index: 9,
    profilePic: require('../assets/images/samuel_nicolle/samuel_nicolle_rata.png'),
    'title_es': 'Beaux Dégâts - tengo un cagadero en casa :Compter les Pieds (provisoire), My infinite chocolate hole, Cocktail d’amore, Agalmatophilia/ Agalmatofilia, Store vénérien-le mâle vénitien, galán de noche, Muchacho culebreando frenéticamente alrededor de la barra, Guárdame Mucho',
    'title_en': 'Beaux Dégâts - tengo un cagadero en casa :Compter les Pieds (provisoire), My infinite chocolate hole, Cocktail d’amore, Agalmatophilia/ Agalmatofilia, Store vénérien-le mâle vénitien, galán de noche, Muchacho culebreando frenéticamente alrededor de la barra, Guárdame Mucho',
    'short_title_es': 'Beaux Dégâts - tengo un cagadero en casa',
    'short_title_en': 'Beaux Dégâts - tengo un cagadero en casa',
    artist: 'Samuel Nicolle',
    'project_desc_es': [
      {
        title: 'Episodio 09: CÓCTEL DE OBJETOS', text: `En este episodio, los muebles nos han puesto las cartas sobre la mesa. “Mientras la lucha por devenir sujeto se enreda en sus propias contradicciones”, dice Hito Steyerl, “surgió una posibilidad diferente: ¿Y si, para variar, nos revistiéramos de objeto?” ¡Vaya exhortación! Dejemos de representar o ser representados, participemos en la configuración material de la imagen, ¡seamos una cosa más! Los indicios para esta transfiguración están en las superficies, porque, sigue Steyerl, “una cosa no es nunca meramente un objeto, sino un fósil en el que una constelación de fuerzas se ha petrificado. Las cosas no son nunca simples trastos inanimados.” Bienvenidx al último baile con tu sujetx interior, dile adiós al galán que te habita y empieza a reconocerte en “cosa que siente”. Sí, somos una cosa más, un fragmento del mundo que empieza y se termina, como una canción. ¿Qué cosa eres tú? ¡Desenmascárate!`
      }
    ],
    'project_desc_en': [
      {
        title: 'Episodio 09: CÓCTEL DE OBJETOS', text: `En este episodio, los muebles nos han puesto las cartas sobre la mesa. “Mientras la lucha por devenir sujeto se enreda en sus propias contradicciones”, dice Hito Steyerl, “surgió una posibilidad diferente: ¿Y si, para variar, nos revistiéramos de objeto?” ¡Vaya exhortación! Dejemos de representar o ser representados, participemos en la configuración material de la imagen, ¡seamos una cosa más! Los indicios para esta transfiguración están en las superficies, porque, sigue Steyerl, “una cosa no es nunca meramente un objeto, sino un fósil en el que una constelación de fuerzas se ha petrificado. Las cosas no son nunca simples trastos inanimados.” Bienvenidx al último baile con tu sujetx interior, dile adiós al galán que te habita y empieza a reconocerte en “cosa que siente”. Sí, somos una cosa más, un fragmento del mundo que empieza y se termina, como una canción. ¿Qué cosa eres tú? ¡Desenmascárate!`
      }
    ],
    media: {
      images: [
        require('../assets/images/samuel_nicolle/samuel_nicolle_1.jpg'),
        require('../assets/images/samuel_nicolle/samuel_nicolle_2.jpg'),
        require('../assets/images/samuel_nicolle/samuel_nicolle_3.jpg'),
        require('../assets/images/samuel_nicolle/samuel_nicolle_4.jpg'),
        require('../assets/images/samuel_nicolle/samuel_nicolle_5.jpg'),
        require('../assets/images/samuel_nicolle/samuel_nicolle_6.jpg'),
        require('../assets/images/samuel_nicolle/samuel_nicolle_7.jpg'),
        require('../assets/images/samuel_nicolle/samuel_nicolle_8.jpg'),
      ],
      videos: [],
    },
    'bio_es': [
      {
        title: '', text: `Samuel Nicolle (Francia, 1992), trabaja desde prácticas queer a través de esculturas, performances, vestuarios, canciones para generar situaciones donde cada uno de estos elementos se desdobla en otro con el fin de explorar la posibilidad de ser imagen, ser objeto. Su investigación se acerca también a la historia LBGTQ+, los años setenta y la música pop. Su trabajo despliega ecosistemas de interiores empapados de huellas de la presencia humana. Nicolle estudió en ESAA Duperré en Paris, Francia, y luego en ENSAPC, en Cergy-Pontoise, Francia antes de moverse a México para entrar al Programa Educativo SOMA. Ha presentado su trabajo en México, Bélgica, Francia y Reino Unido.`
      }
    ],
    'bio_en': [
      {
        title: '', text: `Samuel Nicolle (Francia, 1992), trabaja desde prácticas queer a través de esculturas, performances, vestuarios, canciones para generar situaciones donde cada uno de estos elementos se desdobla en otro con el fin de explorar la posibilidad de ser imagen, ser objeto. Su investigación se acerca también a la historia LBGTQ+, los años setenta y la música pop. Su trabajo despliega ecosistemas de interiores empapados de huellas de la presencia humana. Nicolle estudió en ESAA Duperré en Paris, Francia, y luego en ENSAPC, en Cergy-Pontoise, Francia antes de moverse a México para entrar al Programa Educativo SOMA. Ha presentado su trabajo en México, Bélgica, Francia y Reino Unido.`
      }
    ],
  },
  'perla_ramos': {
    youtube_video: 'vXIYI37zG0g',
    prev_episode: 'wendy_cabrera_rubio',
    next_episode: 'angela_ferrari',
    index: 1,
    noBackgroundPic: true,
    profilePic: require('../assets/images/perla_ramos/perla_ramos_rata.png'),
    'title_es': '#atlasdeletrasturísticas',
    'title_en': '#atlasdeletrasturísticas',
    'short_title_es': '#atlasdeletrasturísticas',
    'short_title_en': '#atlasdeletrasturísticas',
    artist: 'Perla Ramos',
    'project_desc_es': [
      {
        title: 'Episodio 01:   HASHTAG MÁGICO', text: `Como tal vez ya sabes, un hashtag es una etiqueta de metadatos con la que un sistema y sus usuarios identifican información de forma rápida. En este episodio crearemos un observatorio de marca–país e imagen–país etiquetando con un hashtag mágico nuestras joyas monumentales provincianas. Recuerda que para detectar la magia que habita en nuestros territorios debes buscar que estos cumplan con una o varias de las características del acróstico que hemos diseñado para ti: “M” de civilización milenaria; “A” de origen y comienzo de una aventura; “G” de juego, símbolo de encuentro; “I” de verticalidad y aspiraciones modernas; “C” de vitalidad y recursos naturales; “O”, de encuentro de dos mundos. ¡Mágico! Aprende a rotular y a monitorear, dos procedimientos sencillos con los que lograrás fijar la identidad de tu entorno en la mente de lxs espectadorxs. Disfruta haciendo selfies y recuerdos para el álbum familiar al tiempo que contribuyes a capitalizar la reputación de tu pueblo (¡de tu país!) en mercados internacionales. `
      }
    ],
    'project_desc_en': [
      {
        title: 'Episodio 01:   HASHTAG MÁGICO', text: `Como tal vez ya sabes, un hashtag es una etiqueta de metadatos con la que un sistema y sus usuarios identifican información de forma rápida. En este episodio crearemos un observatorio de marca–país e imagen–país etiquetando con un hashtag mágico nuestras joyas monumentales provincianas. Recuerda que para detectar la magia que habita en nuestros territorios debes buscar que estos cumplan con una o varias de las características del acróstico que hemos diseñado para ti: “M” de civilización milenaria; “A” de origen y comienzo de una aventura; “G” de juego, símbolo de encuentro; “I” de verticalidad y aspiraciones modernas; “C” de vitalidad y recursos naturales; “O”, de encuentro de dos mundos. ¡Mágico! Aprende a rotular y a monitorear, dos procedimientos sencillos con los que lograrás fijar la identidad de tu entorno en la mente de lxs espectadorxs. Disfruta haciendo selfies y recuerdos para el álbum familiar al tiempo que contribuyes a capitalizar la reputación de tu pueblo (¡de tu país!) en mercados internacionales. `
      }
    ],
    media: {
      images: [
        require('../assets/images/perla_ramos/perla_ramos_1.jpg'),
        require('../assets/images/perla_ramos/perla_ramos_2.jpg'),
        require('../assets/images/perla_ramos/perla_ramos_3.jpg'),
        require('../assets/images/perla_ramos/perla_ramos_4.jpg'),
        require('../assets/images/perla_ramos/perla_ramos_5.jpg'),
        require('../assets/images/perla_ramos/perla_ramos_6.jpg'),
      ],
      videos: [],
    },
    'bio_es': [
      {
        title: '', text: `Perla Ramos (Cuernavaca, México, 1985) utiliza la escultura, el dibujo y el performance para realizar su práctica artística. Traslada el discurso de su obra a espacios de acción política, la cual transita entre historias oficiales y narrativas ficticias. En sus proyectos de sitio específico, indaga sobre la idea  de lo monumental y lo fragmentado.                                                                                                                                               Cursó la maestría en la Facultad de Artes y Diseño de la UNAM. Estudió la Licenciatura en Artes en  la Universidad Autónoma de Estado de Morelos. Ha participado en diversas exposiciones en México, Argentina, España, Brasil, Ecuador y Puerto Rico, así como en residencias, bienales, talleres y ponencias en relación al espacio público; ha sido beneficiaria de becas para estudios y proyectos artísticos (PECDA, UNAM.) Formó parte de lxs colectivxs: Inmobiliaria de Arte, Obra Paralizada y Grupo de Incursiones Artísticas Metropolitanas. `
      },
    ],
    'bio_en': [
      {
        title: '', text: `Perla Ramos (Cuernavaca, México, 1985) utiliza la escultura, el dibujo y el performance para realizar su práctica artística. Traslada el discurso de su obra a espacios de acción política, la cual transita entre historias oficiales y narrativas ficticias. En sus proyectos de sitio específico, indaga sobre la idea  de lo monumental y lo fragmentado.                                                                                                                                               Cursó la maestría en la Facultad de Artes y Diseño de la UNAM. Estudió la Licenciatura en Artes en  la Universidad Autónoma de Estado de Morelos. Ha participado en diversas exposiciones en México, Argentina, España, Brasil, Ecuador y Puerto Rico, así como en residencias, bienales, talleres y ponencias en relación al espacio público; ha sido beneficiaria de becas para estudios y proyectos artísticos (PECDA, UNAM.) Formó parte de lxs colectivxs: Inmobiliaria de Arte, Obra Paralizada y Grupo de Incursiones Artísticas Metropolitanas. `
      },
    ],
    'links_es': [
      {
        texts: [],
        textLink: 'DESCARGA E IMPRIME EL ANVERSO Y REVERSO_L1 HASHTAG MÁGICO',
        link: require('../assets/pdf/perla_ramos/DESCARGA_E_IMPRIME_EL_ANVERSO_Y_REVERSO_L1_HASHTAG_MAGICO.pdf')
      },
      {
        texts: [],
        textLink: 'DESCARGA E IMPRIME EL ANVERSO Y REVERSO_L2 HASHTAG MÁGICO',
        link: require('../assets/pdf/perla_ramos/DESCARGA_E_IMPRIME_EL_ANVERSO_Y_REVERSO_L2_HASHTAG_MAGICO_.pdf')
      },
      {
        texts: [],
        textLink: 'Instagram: @atlasdeletrasturisticas',
        link: 'https://www.instagram.com/atlasdeletrasturisticas/'
      }
    ],
    'link_en': [
      {
        texts: [
          'Serie de dibujos para el libro "Generación Espontánea" de Ángela Ferrari. Selección 10/30.',
          'Grafito sobre papel acuarelable.',
          'Medidas variables.',
          '2017-actualidad.'
        ],
        textLink: 'Descargar portafolio completo aquí.',
        link: require('../assets/pdf/angela_ferrari/Portfolio_Angela_Ferrari.pdf')
      }
    ],
  },
  'israel_urmeer': {
    youtube_video: 'Cy_K9nbzSwQ',
    prev_episode: 'clemente_castor',
    next_episode: 'josue_mejia',
    index: 4,
    profilePic: require('../assets/images/israel_urmeer/israel_urmeer_rata.png'),
    'title_es': '¡POPOOP! la montaña humeante ',
    'title_en': '¡POPOOP! la montaña humeante ',
    'short_title_es': '¡POPOOP! la montaña humeante ',
    'short_title_en': '¡POPOOP! la montaña humeante ',
    artist: 'Israel Urmeer',
    'project_desc_es': [
      {
        title: 'Episodio 04: NARANA PUM, PUM, POPOCATÉPETL', text: `
        Queremos tanto a Don Goyo. Estamos siempre a la espera de su explosión, ¿cierto? No es
        raro, por eso mismo, que la representación de esta figura mitológica masculina se
        presente por doquier, como parte del dispositivo visual que sigue intentando
        conglomerarnos en un Estado Nación, a como dé lugar. En este episodio no
        construiremos una maqueta escolar, ni haremos la típica reacción química entre vinagre y
        bicarbonato, sino algo totalmente distinto. Resulta que este cráter monolítico controla y
        habita el tiempo: aparece en el anacronismo de la pintura, en los calendarios colgados en
        la cocina de nuestra casa e incluso en la omnipresente y sofisticada vigilancia 24 horas
        del CENAPRED. ¿Cómo traducir el lenguaje temporal de este faro de lava? Explora sus
        representaciones, desde el mito fundacional hasta su conformación como ídolo pop, y
        diseña tu propia agenda volcánica.`
      }
    ],
    'project_desc_en': [
      {
        title: 'Episodio 04: NARANA PUM, PUM, POPOCATÉPETL', text: `
        Queremos tanto a Don Goyo. Estamos siempre a la espera de su explosión, ¿cierto? No es
        raro, por eso mismo, que la representación de esta figura mitológica masculina se
        presente por doquier, como parte del dispositivo visual que sigue intentando
        conglomerarnos en un Estado Nación, a como dé lugar. En este episodio no
        construiremos una maqueta escolar, ni haremos la típica reacción química entre vinagre y
        bicarbonato, sino algo totalmente distinto. Resulta que este cráter monolítico controla y
        habita el tiempo: aparece en el anacronismo de la pintura, en los calendarios colgados en
        la cocina de nuestra casa e incluso en la omnipresente y sofisticada vigilancia 24 horas
        del CENAPRED. ¿Cómo traducir el lenguaje temporal de este faro de lava? Explora sus
        representaciones, desde el mito fundacional hasta su conformación como ídolo pop, y
        diseña tu propia agenda volcánica.`
      }
    ],
    media: {
      images: [
        require('../assets/images/israel_urmeer/israel_urmeer_1.jpg'),
        require('../assets/images/israel_urmeer/israel_urmeer_2.jpg'),
        require('../assets/images/israel_urmeer/israel_urmeer_3.jpg'),
        require('../assets/images/israel_urmeer/israel_urmeer_4.jpg'),
        require('../assets/images/israel_urmeer/israel_urmeer_5.jpg'),
        require('../assets/images/israel_urmeer/israel_urmeer_6.jpg'),
        require('../assets/images/israel_urmeer/israel_urmeer_7.jpg'),
        require('../assets/images/israel_urmeer/israel_urmeer_8.jpg'),
        require('../assets/images/israel_urmeer/israel_urmeer_9.jpg'),
        require('../assets/images/israel_urmeer/israel_urmeer_10.jpg'),
      ],
      videos: [],
    },
    'bio_es': [
      {
        title: '', text: `Israel Urmeer (Ciudad de México, años noventas).  Su trabajo a manera de un caleidoscopio se sacude y combina entre la escultura, la animación y el dibujo. Su obra se adentra en el tecno paisaje de la cultura pop, para modelar una lectura particular de ciertas influencias icónicas y su impacto en la imaginación. 
        Cursó sus estudios en la Facultad de Artes y Diseño de la UNAM (2012-2015) y tomó distintos seminarios en Campus Expandido del MUAC en la UNAM, posteriormente ingresó al Programa Educativo de SOMA (2018-2020). Su obra se ha presentado colectivamente en el Museo Carillo Gil, Museo del Chopo, Laboratorio de Arte Alameda, Palais de Tokyo, FUEGO CDMX, entre otros. Y de manera individual en Head Geneve y House of Deslave. Urmeer forma parte del proyecto del Museo Comunitario de Sierra Hermosa. Fue integrante de GAMA (2014-2016) en la Facultad de Artes y diseño UNAM, Zona de Desgaste (2015-2016), y actualmente es miembro activo de Biquini Wax EPS.
         
        <i>Somos la sustancia dentro de la botella Coca-Cola</i>.`
      },
    ],
    'bio_en': [
      {
        title: '', text: `Israel Urmeer (Ciudad de México, años noventas).  Su trabajo a manera de un caleidoscopio se sacude y combina entre la escultura, la animación y el dibujo. Su obra se adentra en el tecno paisaje de la cultura pop, para modelar una lectura particular de ciertas influencias icónicas y su impacto en la imaginación. 
        Cursó sus estudios en la Facultad de Artes y Diseño de la UNAM (2012-2015) y tomó distintos seminarios en Campus Expandido del MUAC en la UNAM, posteriormente ingresó al Programa Educativo de SOMA (2018-2020). Su obra se ha presentado colectivamente en el Museo Carillo Gil, Museo del Chopo, Laboratorio de Arte Alameda, Palais de Tokyo, FUEGO CDMX, entre otros. Y de manera individual en Head Geneve y House of Deslave. Urmeer forma parte del proyecto del Museo Comunitario de Sierra Hermosa. Fue integrante de GAMA (2014-2016) en la Facultad de Artes y diseño UNAM, Zona de Desgaste (2015-2016), y actualmente es miembro activo de Biquini Wax EPS.
         
        <i>Somos la sustancia dentro de la botella Coca-Cola</i>.`
      },
    ],
  },
  'marek_wolfryd': {
    youtube_video: '2RcdAa56yGo',
    prev_episode: 'josue_mejia',
    next_episode: 'martin_bernstein',
    index: 6,
    overflowHidden: true,
    profilePic: require('../assets/images/marek_wolfryd/marek_wolfryd_rata.png'),
    'title_es': 'Portafolio de inversión #1 (Cuando todo lo demás falla la persistencia te demostrará el camino)',
    'title_en': 'Portafolio de inversión #1 (Cuando todo lo demás falla la persistencia te demostrará el camino)',
    'short_title_es': 'Portafolio de inversión #1 (Cuando todo lo demás falla la persistencia te demostrará el camino)',
    'short_title_en': 'Portafolio de inversión #1 (Cuando todo lo demás falla la persistencia te demostrará el camino)',
    artist: 'Marek Wolfryd',
    'project_desc_es': [
      {
        title: 'Episodio 6 COLECCIONISMO FRACCIONAL ', text: `Se sabe desde hace tiempo que hay una simetría casi exacta entre una obra de arte y un algoritmo financiero. No por nada, haciendo un juego con la palabra “duty” (que en inglés significa “impuesto” u “obligación”), la artista Hito Steyerl propone que incluso si las obras de arte estuvieran libres de impuestos (como sucede en algunos espacios de almacenamiento aeroportuario), no estarían nunca libres de obligaciones. Las obras de arte siempre “tienen la obligación de ser activos”. Con solo una conexión a internet y un presupuesto, sigue paso a paso el método más adecuado para invertir en una porción (por mínima que sea) de una obra de arte valuada en millones en el mercado internacional. Aprenderás a hacer una representación en escala 1:1 de tu adquisición y todos los secretos del coleccionismo fraccional, ¡el mejor negocio para asegurar tu futuro!`
      }
    ],
    'project_desc_en': [
      {
        title: 'Episodio 6 COLECCIONISMO FRACCIONAL ', text: `Se sabe desde hace tiempo que hay una simetría casi exacta entre una obra de arte y un algoritmo financiero. No por nada, haciendo un juego con la palabra “duty” (que en inglés significa “impuesto” u “obligación”), la artista Hito Steyerl propone que incluso si las obras de arte estuvieran libres de impuestos (como sucede en algunos espacios de almacenamiento aeroportuario), no estarían nunca libres de obligaciones. Las obras de arte siempre “tienen la obligación de ser activos”. Con solo una conexión a internet y un presupuesto, sigue paso a paso el método más adecuado para invertir en una porción (por mínima que sea) de una obra de arte valuada en millones en el mercado internacional. Aprenderás a hacer una representación en escala 1:1 de tu adquisición y todos los secretos del coleccionismo fraccional, ¡el mejor negocio para asegurar tu futuro!`
      }
    ],
    media: {
      images: [
        require('../assets/images/marek_wolfryd/marek_wolfryd_0.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_1.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_2.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_3.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_4.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_5.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_6.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_7.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_8.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_9.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_10.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_11.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_12.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_13.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_14.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_15.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_16.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_17.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_18.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_19.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_20.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_21.jpg'),
        require('../assets/images/marek_wolfryd/marek_wolfryd_22.jpg'),

      ],
      videos: [],
    },
    'bio_es': [
      {
        title: '', text: `Marek Wolfryd, (Ciudad de México, 1989) investiga las prácticas artísticas y los fenómenos culturales como manifestaciones correspondientes al desarrollo de las sociedades contemporáneas. 
        Está interesado en la apropiación como principal proceso para una revisión de nociones como la figura del artista, la estratificación e instrumentalización de la cultura y los relatos históricos del arte. Algunos conceptos recurrentes en su trabajo son el proyecto de modernidad en el contexto de América Latina, los valores simbólicos en la cultura popular y la autoría como instrumento de poder.
        A nivel formal Wolfryd explora distintos medios y formatos artísticos, teniendo también un interés en la curaduría, museografía y promoción cultural como brazos extendidos de su trabajo. Cursó sus estudios de licenciatura en la E.N.P.E.G. “La Esmeralda” y posteriormente entró al Programa Educativo SOMA. Su obra se ha presentado colectivamente en instituciones como Human Resources LA, el Laboratorio de Arte Alameda, Aoyama Maguro y La Tallera. Destacan exposiciones individuales en espacios como el Museo Universitario del Chopo/1000, Biquini Wax EPS y la Chalton Gallery de Londres. `
      },
    ],
    'bio_en': [
      {
        title: '', text: `Marek Wolfryd, (Ciudad de México, 1989) investiga las prácticas artísticas y los fenómenos culturales como manifestaciones correspondientes al desarrollo de las sociedades contemporáneas. 
        Está interesado en la apropiación como principal proceso para una revisión de nociones como la figura del artista, la estratificación e instrumentalización de la cultura y los relatos históricos del arte. Algunos conceptos recurrentes en su trabajo son el proyecto de modernidad en el contexto de América Latina, los valores simbólicos en la cultura popular y la autoría como instrumento de poder.
        A nivel formal Wolfryd explora distintos medios y formatos artísticos, teniendo también un interés en la curaduría, museografía y promoción cultural como brazos extendidos de su trabajo. Cursó sus estudios de licenciatura en la E.N.P.E.G. “La Esmeralda” y posteriormente entró al Programa Educativo SOMA. Su obra se ha presentado colectivamente en instituciones como Human Resources LA, el Laboratorio de Arte Alameda, Aoyama Maguro y La Tallera. Destacan exposiciones individuales en espacios como el Museo Universitario del Chopo/1000, Biquini Wax EPS y la Chalton Gallery de Londres. `
      },
    ],
  },
}

export const getEpisode = (episode = '') => {
  return episodes[episode] ? episodes[episode] : null
}


export const getEpisodes = (sort = false) => {
  let episodeList = Object.keys(episodes).map(key => ({ ...episodes[key], id: key }))
  if (sort) episodeList.sort((current, next) => current.index - next.index)
  return episodeList
}