import React, { useState, useEffect } from 'react'
import Masonry from 'react-masonry-css'
import Background from '../../baseComponents/BackgroundImage'
import Header from '../../baseComponents/Header'
import BoxContainer from '../../baseComponents/BoxContainer'
import FloatingObjects from '../../baseComponents/FloatingObjects'
import { getEvents } from '../../services/events'
import { connect } from 'react-redux'
import AnimatedBackground from '../../baseComponents/AnimatedBackground'
import { accents } from '../../utils/accents'

import './PublicProgram.css'

const PublicProgram = props => {
  let { lang } = props.appState
    , [width, setWidth] = useState(100)
    , onResize = () => { setWidth(window.innerWidth) }

  const events = getEvents()

  const onStartTransition = () => {
  }

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])


  const onDonwload = () => {
    window.open('https://www.youtube.com/channel/UCiseHx1nmbtgT4ooDUAfrzg?view_as=subscriber', '_blank')
  }

  const renderDesktop = () => {
    return <Masonry columnClassName='' breakpointCols={{ default: 2, 800: 1 }} className="masonry-grid" >
      {
        rendererMobil()
      }
    </Masonry>
  }

  const renderHyperLink = (text, hyperlink) => {
    return text.replace(hyperlink.keyText, `<a class='link' href='${hyperlink.link}' target='__blank' >${hyperlink.keyText}</a>`)
  }

  const rendererMobil = () => {
    return events.map(event => <div key={event.id} className='event-box-container'>
      <BoxContainer className={`event-box ${event.hideContainer ? 'hide-container-' : ''}`}>
        {
          !event.hideContainer
            ? <React.Fragment>
              <h2 className='accents' dangerouslySetInnerHTML={{ __html: accents(event[`title_${lang}`]) }} />
              {
                event.videoURL
                  ? <div className='video-container'>
                    <iframe title={event.title} src={`https://www.youtube.com/embed/${event.videoURL}?rel=0`} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
                  </div>
                  : <div className='banner background-image' style={{ background: `url("${event.banner}")` }} />
              }
            </React.Fragment>
            : null
        }
        <div className='event-box-content'>
          <p className="author">{event.author}</p>
          <p className="date">{event[`date_${lang}`]}</p>
          {
            event[`abstract_${lang}`].map((paragraph, index) => {
              return <React.Fragment key={index}>
                <p>{paragraph.title}</p>
                {
                  paragraph.hasLink
                    ? <p dangerouslySetInnerHTML={{ __html: renderHyperLink(paragraph.text, paragraph.hyperlink) }} />
                    : <p>{paragraph.text}</p>
                }
              </React.Fragment>
            })
          }
          {
            event[`semblance_${lang}`].map((paragraph, index) => <React.Fragment key={index}>
              <p className='semblance-text'>{paragraph.title}</p>
              <p className='semblance-text'>{paragraph.text}</p>
            </React.Fragment>)
          }
        </div>
      </BoxContainer>
    </div>)
  }


  return <Background>
    <AnimatedBackground />
    <div id='public-program'>
      <Header onStartTransition={onStartTransition} time={2400} />
      <div className='container'>
        <FloatingObjects />
        <h1 className='title'>
          Programa virtual y gratuito, <br />transmitido en vivo.
        </h1>
        <p className='description link'>8:30 pm a través del canal de YouTube: <span onClick={onDonwload}>Miércoles de SOMA</span></p>
        <div className='content'>
          {
            width < 760
              ? rendererMobil()
              : renderDesktop()
          }
        </div>
      </div>
    </div>
  </Background>
}

const mapStateToPros = state => ({
  appState: state.appState
})

export default connect(mapStateToPros)(PublicProgram)