import React from 'react'
import rainbow from '../assets/images/gradient.jpg'
import './CirclePicture.css'

const CirclePicture = props => {
  let bgType = props.disableBackground ? 'bg-transparent' : ''
    , overflow = props.overflowHidden ? 'overflow-hidden' : ''
  return <div className={`circle-container background-image profile-pic ${props.className}`} style={{ background: `url(${rainbow})` }}>
    <div className={`circle-content ${bgType} ${overflow}`}>
      <img className='circle-pic' src={props.image} alt={props.alt} />
    </div>
  </div>
}

export default CirclePicture
