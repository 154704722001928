import React from 'react'
import Privacy from '../../baseComponents/PrivacyNotification'
import AnimatedBackground from '../../baseComponents/AnimatedBackground'
import Background from '../../baseComponents/BackgroundImage'

const PrivacyNotification = props => {
  return <Background>
    <AnimatedBackground />

    <Privacy />
  </Background>

}

export default PrivacyNotification