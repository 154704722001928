export const setLoaderState = (isLoaderActive = false) => ({
  type: 'SET_LOADER_STATUS',
  isLoaderActive
})

export const setAppointmentStatus = (appointmentsFetched = false) => ({
  type: 'SET_APPOINMENT_STATUS',
  appointmentsFetched
})

export const setAppointmentList = (appointments = {}) => ({
  type: 'SET_APPOINTMENT_LIST',
  appointments
})

export const setLang = (lang = 'es') => ({
  type: 'SET_LANG',
  lang
})