import React from 'react'
import rainbow from '../assets/images/gradient.jpg'

import './BoxContainer.css'

const BoxContainer = props => {
  return <div className={`box-container background-image ${props.className}`} style={{ background: `url(${rainbow})` }}>
    <div className='box-content'>
      {props.children}
    </div>
  </div>
}

export default BoxContainer