const initialState = {
  isLoaderActive: true,
  lang: 'es',
  appointments: {},
  appointmentsFetched: false
}

const appStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADER_STATUS': return { ...state, isLoaderActive: action.isLoaderActive }
    case 'SET_LANG': return { ...state, lang: action.lang }
    case 'SET_APPOINTMENT_LIST': return { ...state, appointments: action.appointments }
    case 'SET_APPOINMENT_STATUS': return { ...state, appointmentsFetched: action.appointmentsFetched }
    default: return { ...state }
  }
}

export default appStateReducer