import React from 'react'
import Background from '../../baseComponents/BackgroundImage'
import Header from '../../baseComponents/Header'
import { connect } from 'react-redux'
import FloatingObjects from '../../baseComponents/FloatingObjects'
import AnimatedBackground from '../../baseComponents/AnimatedBackground'

import 'react-image-gallery/styles/css/image-gallery.css'

import './Expo.css'

const Episode = (props) => {

  const onStartTransition = () => { }

  return <Background>
    <AnimatedBackground />
    <div id='episode'>
      <Header onStartTransition={onStartTransition} time={2400} />
      <div className='container'>
        <FloatingObjects />

        <div className='video-container'>
          <iframe title='video' src={`https://www.youtube.com/embed/jrCU25bgl8g?rel=0`} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
        </div>

      </div>
    </div>
  </Background >
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(Episode)









/*
import React from 'react'
import Background from '../../baseComponents/BackgroundImage'
import Header from '../../baseComponents/Header'
import { connect } from 'react-redux'
import BoxContainer from '../../baseComponents/BoxContainer'
import CirclePicture from '../../baseComponents/CirclePicture'
import ImageGallery from 'react-image-gallery'
import { getEpisode } from '../../services/episodes'
import { loaderTransition } from '../../utils/transitions'
import { getUrl } from '../../utils/urls'
import FloatingObjects from '../../baseComponents/FloatingObjects'
import AnimatedBackground from '../../baseComponents/AnimatedBackground'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import { accents } from '../../utils/accents'

import 'react-image-gallery/styles/css/image-gallery.css'

import './Expo.css'

const Expo = props => {

  const onStartTransition = () => { }

  return <Background>
    <AnimatedBackground />
    <div id='expo'>
      <Header onStartTransition={onStartTransition} time={2400} />
      <div className='container'>
        <FloatingObjects />
        <div className='video-container'>
          <iframe title='video' src={`https://www.youtube.com/embed/jrCU25bgl8g?rel=0`} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
        </div>
      </div>
    </div>
  </Background>
}

export default Expo

*/