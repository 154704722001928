import store from '../redux/store'

const langTexts = {
  'privacy': {
    'es': {
      'text': [
        {
          title: 'AVISO DE PRIVACIDAD', sub: [
            { title: '', text: `LA PANADERIA CENTRO CULTURAL DE ARTE A.C., con domicilio ubicado en: Calle 13, no. 25 Col. San Pedro de los Pinos, Alcaldía Benito Juárez, CP 03800, Ciudad de México, en cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de Particulares (“la Ley”), y su reglamento, hacen de su conocimiento que: “SOMA”, protege y salvaguarda sus datos personales para evitar el daño, pérdida, destrucción, robo, extravío, alteración, así como el tratamiento no autorizado.` },
            {
              title: '¿Qué información recopilamos?', text: `Los datos personales que usted proporcione a “SOMA” a través de cualquier medio físico o electrónico podrían incluir: (i) datos personales, (ii) datos laborales e (iii) información financiera o contable.
            El tratamiento de datos personales de terceros que usted nos proporcione, se hace de conformidad con los términos y condiciones contenidos en el Aviso de Privacidad.`},
            { title: '', text: `Usted manifiesta su consentimiento para el tratamiento de sus datos personales de acuerdo a los términos y condiciones de este Aviso de Privacidad.` },
            {
              title: '¿Para qué utilizamos su información?', text: `Los datos personales que recopilamos son destinados para: (i) fines de identificación y de verificación, (ii) información de nuestros clientes o aliados, (iii) definir perfiles de usuario y (iv) envío de promociones propias.
            En la recolección y tratamiento de datos personales, cumplimos con todos los principios estipulados en la Ley y su Reglamento: licitud, calidad, consentimiento, información, finalidad, lealtad, proporcionalidad y responsabilidad.`},
            { title: '¿Con quién compartimos su información?', text: `“SOMA” puede transferir sus datos personales a terceros nacionales o extranjeros pertenecientes a nuestros grupo económico, a nuestras empresas subsidiarias y relacionadas; por requerimiento de una autoridad; y para el cumplimiento de obligaciones derivadas de una relación jurídica entre el titular de los datos personales y “SOMA”.` },
            { title: '', text: `Los datos personales que usted proporciona a “SOMA”, pueden compilarse y fijarse en una base de datos propiedad exclusiva de “SOMA” para ser utilizada en los términos antes referidos.` },
            { title: '¿Qué medidas de seguridad y control utilizamos para la protección de sus datos personales?', text: `“SOMA”, implementa medidas de seguridad administrativas, técnicas y físicas para proteger sus datos personales, mismas que exigimos sean cumplidas por nuestros proveedores de servicios o clientes, inclusive tratándose de servicios que prestan las empresas subsidiarias o relacionadas.` },
            { title: '', text: `Toda la información y datos personales que usted nos proporcione mediante una vista, en la utilización de nuestros servicios en línea, por escrito y/o vía telefónica, constituirá una base de datos de “SOMA”. La información se almacena con fines de protección, para evitar pérdida, uso indebido o alteración.` },
            { title: '', text: `“SOMA” puede utilizar en su sitio web “cookies” o “direcciones IP”, para confirmar su identificación al tener acceso a nuestros servicios en línea, con el sólo propósito de otorgarle un servicio personalizado debido a que nos permite determinar sus preferencias mediante el registro de su uso en nuestro portal, y de esta manera identificar sus hábitos y preferencias para ofrecerle nuestros productos y servicios. Mediante el uso de “cookies”, no recabamos datos personales de los usuarios en términos de la Ley y su Reglamento.` },
            { title: '', text: `La seguridad y la confidencialidad de los datos que los usuarios proporcionen al comprar una franquicia con nosotros o adquirir algunos de nuestros productos o servicios estarán protegidas por un servidor seguro, de tal forma que los datos recabados si se llegasen a transmitir, se transmitirán encriptados para asegurar su resguardo.` },
            { title: '¿Cuál es el área responsable del manejo y administración de los datos personales?', text: `El área responsable del manejo y la administración de los datos personales puede contactarse mediante el correo electrónico: info@somamexico.org, o directamente en nuestras oficinas ubicadas en: Calle 13, no. 25, Col. San Pedro de los Pinos, Alcaldía Benito Juárez, CP 03800, Ciudad de México.` },
            { title: '¿Cómo pude usted limitar el uso o divulgación de sus datos personales?', text: `Puede dirigirse a “SOMA” para el caso de que desee limitar el uso o divulgación de sus datos personales para dejar de recibir información de ofertas, promociones y publicidad de productos y servicios adicionales enviando su solicitud a través de la cuenta de correo electrónico info@somamexico.org como al teléfono 55 52774947` },
            { title: '', text: `En el caso de empleo de cookies, el botón “ayuda” que se encuentra en la barra de herramientas de la mayoría de los navegadores, le dirá cómo bloquear o permitir las cookies.` },
            { title: '¿Cómo puede usted ejercer sus derechos ARCO y revocar el consentimiento otorgado?', text: `Como titular de datos personales, usted podrá́ ejercer los Derechos ARCO (acceso, cancelación, rectificación y oposición al tratamiento de datos personales), o bien, revocar el consentimiento que usted haya otorgado a “SOMA” para el tratamiento de sus datos personales, enviando su solicitud, directamente a la cuenta de correo electrónico info@somamexico.org.; o acudiendo directamente a nuestras oficinas; o bien, solicitándolo por escrito, enviando al domicilio Calle 13, no. 25, Col. San Pedro de los Pinos, Alcaldía Benito Juárez, Ciudad de México. Dicha solicitud deberá́ contener por lo menos su nombre, domicilio completo, documentos que acrediten su identidad, señalando claramente el vínculo de relación con la Compañía y especificando en forma clara y precisa los datos personales de los que solicita su acceso, rectificación, actualización o cancelación, los elementos o documentos en donde pueden obrar los datos personales e indicando las razones por las cuales desea acceder a sus datos personales, o las razones por las que considera que sus datos deben ser actualizados, rectificados o cancelados. o bien, solicitándolo por escrito, enviando al domicilio Calle 13, no. 25, Col. San Pedro de los Pinos, Alcaldía Benito Juárez, Ciudad de México. Dicha solicitud deberá́ contener por lo menos su nombre, domicilio completo, documentos que acrediten su identidad, señalando claramente el vínculo de relación con la Compañía y especificando en forma clara y precisa los datos personales de los que solicita su acceso, rectificación, actualización o cancelación, los elementos o documentos en donde pueden obrar los datos personales e indicando las razones por las cuales desea acceder a sus datos personales, o las razones por las que considera que sus datos deben ser actualizados, rectificados o cancelados.` },
            {
              title: '', text: `A partir de la recepción, el área de Privacidad de Datos, tendrá un plazo de 20 (veinte) días hábiles para responder a su solicitud, sin embargo, dicho plazo podrá ser ampliado por un periodo de tiempo igual, lo que le será notificado, por el medio que usted nos haya indicado para tales efectos, señalando las razones de dicha ampliación, de conformidad con el artículo 32 de la Ley.
            Modificaciones al Aviso de Privacidad
            Nos reservamos el derecho de cambiar este Aviso de Privacidad en cualquier momento. En caso de que exista algún cambio en este Aviso de Privacidad, usted tendrá acceso al mismo a través de nuestro Portal http://rataattack.somamexico.org`},
            { title: 'Consentimiento de tratamiento de datos personales', text: `` },
            {
              title: '', text: `El titular de datos personales manifiesta haber leído y estar de acuerdo con los términos y condiciones del Aviso de Privacidad puesto a su disposición, conociendo la finalidad de la recolección y tratamiento de sus datos personales, así como el procedimiento para el ejercicio de sus Derechos ARCO.
            Al proporcionar información vía internet, telefónica o personalmente, usted otorga su consentimiento al presente Aviso de Privacidad.
            Ante quién puede presentar sus quejas y denuncias por el tratamiento indebido de sus datos personales
            Si usted considera que su derecho de protección de datos personales ha sido transgredido por alguna conducta de nuestros empleados o de nuestras actuaciones o respuestas, presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, podrá interponer la queja o denuncia correspondiente ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI), para mayor información, le sugerimos visitar su página oficial de internet www.inai.org.mx.`},
            {
              title: '', text: `Fecha de última actualización de este Aviso de Privacidad: 10/10/2018
            `},
            { title: '', text: `` },
          ]
        },
      ],
    },
    'en': {
      'text': [
        {
          title: 'Créditos exposición', sub: [
            { title: 'Coordinación: ', text: `Marcela Chao Ruiz` },
            { title: 'Acompañamiento: ', text: `Ilana Boltvinik` },
            { title: 'Montaje: ', text: `Juan Rosas, Carlos Barragán y Llamas a mí` },
            { title: 'Tutores: ', text: `Ilana Boltvinik, Virginia Colwell, Ana Gallardo, Veronica Gerber Bicecci, Galia Eibenschutz, Joaquín Segura, Benjamín Torres y Pablo Vargas Lugo` },
          ]
        },
      ],
    }
  },
  'home': {
    'es': {
      'episode': 'Episodio',
      'pdf': require('../assets/pdf/Hoja_Sala_SOMA_RatAttack.pdf'),
      'pdf2': require('../assets/pdf/Rat_Attack_web.pdf')
    },
    'en': {
      'episode': 'Episode',
      'pdf': require('../assets/pdf/Hoja_Sala_SOMA_RatAttack.pdf'),
      'pdf2': require('../assets/pdf/Rat_Attack_web.pdf')
    }
  },
  'appointment': {
    'es': {
      "comment": 'Comentario: ',
      "tickets_error": "Seleccione correctamente la fecha y el número de asistentes al evento",
      "error_text": "¡Oh no! Algo salió mal, por favor intenta más tarde.",
      "success_text": "Gracias",
      "success_text_2": "Nos pondremos en contacto contigo",
      "date_not_available": "Fecha no disponible",
      "sold_out": "No hay entradas disponibles",
      "email_already_registered": "El correo ya ha sido registrado para este evento",
      "not_enough_stock": "No tenemos la cantidad de entradas solicitadas",
      'empty_guest_fields': 'Por favor llena los nombres de todos tus invitados',
      'guest': 'Nombre del invitado',
      'more_than': 'El número solicitado de entradas no esta disponible',
      'less_than_zero': 'El número mínimo de entradas es 1',
      'not_a_number': 'Ingrese un número entero',
      'amout_of_tickets': 'Ingrese el número de boletos',
      'title': 'Agenda tu cita a la exposición',
      'description': [
        { type: 'paragraph', text: 'En SOMA estamos conscientes de la situación actual relacionada al COVID-19 y para realizar visitas a Rat Attack tomamos en cuenta los lineamientos de salud e higiene que implementa la Secretaría de Salud de la Ciudad de México.', },
        { type: 'paragraph', text: '', },
        {
          type: 'list', items: [
            'Para mantener una sana distancia y reducir la probabilidad de contagio, las visitas tendrán un máximo de 10 personas por grupo.',
            'Es obligatorio el uso de cubrebocas y/o careta.',
            'Para acceder a las instalaciones, el personal de SOMA tomará la temperatura. No podrán ingresar personas con más de 37.4º',
            'Todos los visitantes deberán aplicarse gel antibacterial y cruzar por los tapetes sanitizantes.',
            'Se deberá respetar el sentido del recorrido de acuerdo a las señales.',
            'Solicitamos mantener las medidas de protección: estornudo de etiqueta, lavado de manos, no tocar objetos ni superficies, y sana distancia de 1.5 m ',
            'Las áreas comunes de SOMA son sanitizadas constantemente.',
          ]
        },
        { type: 'paragraph', text: '', },
        { type: 'paragraph', text: 'En caso de no estar bien de salud es preferible no asistir a la institución', },
      ],
      'name': 'Nombre',
      'email': 'Correo electrónico',
      'phone': 'Teléfono',
      'm_9': 'Septiembre',
      'm_10': 'Octubre',
      'd_4': 'Jueves',
      'd_5': 'Viernes',
      'd_6': 'Sábado',
      'empty_field': 'Todos los campos son obligatorios',
      'wrong_email': 'El correo electrónico no cumple con el formato correcto: ejemplo@mail.com',
      'wrong_phone_format': 'El número de teléfono proporcionado no parece valido',
      'fetch_error': 'No se pudieron obtener los datos del servidor'
    },
    'en': {
      "comment": 'Comment: ',
      "tickets_error": "Correctly select the date and number of attendees to the event",
      "error_text": "Oh no! Something went wrong, please try again later.",
      "success_text": "Thanks",
      "success_text_2": "We will get in touch with you",
      "date_not_available": "Date not available",
      "sold_out": "No tickets available",
      "email_already_registered": "The email has already been registered for this event",
      "not_enough_stock": "We do not have the number of tickets requested",
      'empty_guest_fields': 'Please fill in the names of all your guests',
      'guest': 'Guest name',
      'more_than': 'The requested number of tickets is not available',
      'less_than_zero': 'The minimum number of entries is 1',
      'not_a_number': 'Please enter a whole number',
      'amout_of_tickets': 'Enter the number of tickets',
      'title': 'Agenda tu cita a la exposición',
      'description': [
        { type: 'paragraph', text: 'En SOMA estamos conscientes de la situación actual relacionada al COVID-19 y para realizar visitas a Rat Attack tomamos en cuenta los lineamientos de salud e higiene que implementa la Secretaría de Salud de la Ciudad de México.', },
        { type: 'paragraph', text: '', },
        {
          type: 'list', items: [
            'Para mantener una sana distancia y reducir la probabilidad de contagio, las visitas tendrán un máximo de 10 personas por grupo.',
            'Es obligatorio el uso de cubrebocas y/o careta.',
            'Para acceder a las instalaciones, el personal de SOMA tomará la temperatura. No podrán ingresar personas con más de 37.4º',
            'Todos los visitantes deberán aplicarse gel antibacterial y cruzar por los tapetes sanitizantes.',
            'Se deberá respetar el sentido del recorrido de acuerdo a las señales.',
            'Solicitamos mantener las medidas de protección: estornudo de etiqueta, lavado de manos, no tocar objetos ni superficies, y sana distancia de 1.5 m ',
            'Las áreas comunes de SOMA son sanitizadas constantemente.',
          ]
        },
        { type: 'paragraph', text: '', },
        { type: 'paragraph', text: 'En caso de no estar bien de salud es preferible no asistir a la institución', },
      ],
      'name': 'Nombre',
      'email': 'Correo electrónico',
      'phone': 'Teléfono',
      'm_9': 'Septiembre',
      'm_10': 'Octubre',
      'd_4': 'Jueves',
      'd_5': 'Viernes',
      'd_6': 'Sábado',
      'empty_field': 'Todos los campos son obligatorios',
      'wrong_email': 'El correo electrónico no cumple con el formato correcto: ejemplo@mail.com',
      'wrong_phone_format': 'El número de teléfono proporcionado no parece valido',
      'fetch_error': 'No se pudieron obtener los datos del servidor'
    }
  },
  'episode': {
    'es': {},
    'en': {}
  },
  'episodes': {
    'es': {
      'title': '¡La única miniserie de arte contemporáneo conducida por ratas!',
      'description': `Una mínima trasposición de letras puede convertir la palabra Art (arte) en Rat (rata). Y eso es, precisamente, esta miniserie: el anagrama de Art Attack, el show de manualidades que cautivó a cientos de niñxs nacidxs en los noventa y en el que, según el slogan, “no necesitas ser un experto para ser un gran artista”. <i>Rat Attack!</i> homenajea la supuesta inferioridad de las manualidades y parodia el aura intelectual y teórica del arte. ¿Quién mejor que un grupo de ratas podría guiarnos a través de las discusiones subterráneas y los submundos del arte contemporáneo del siglo XXI? El recorrido es sinuoso: de la maleza al hashtag, de los objetos encontrados a las realidades paralelas, de lo grotesco a lo mitológico, de la inversión financiera a la importación de sistemas de opresión. Pero no te preocupes: cada capítulo te ofrecerá herramientas de autoaprendizaje, apreciación visual y reflexión para cuestionar, repensar, resistir o, incluso, ¡unirte al sistema del arte! Dile adiós a la pasividad al otro lado de la pantalla y únete al ataque de las ratas. Comparte esta aventura plástica con toda tu familia.`,
      'author': 'Verónica Gerber Bicecci',
      'download_text': {
        'text': 'Descarga el texto completo aquí',
        'url': require('../assets/pdf/RATATTACK_es.pdf'),
      }
    },
    'en': {
      'title': 'The only contemporary art miniseries hosted by rats! ',
      'description': `A slight transposition of letters can turn the word Art into Rat. And that is precisely what
      this miniseries is: the anagram of Art Attack, the craft show that captivated hundreds of
      children born in the nineties and in which, according to the slogan, “you don't need to be
      an expert to be a great artist”. Rat Attack! pays homage to the supposed inferiority of
      crafts, and parodies the intellectual and theoretical aura of art. Who, better than a group
      of rats, could guide us through the subterranean discussions and underworlds of
      contemporary art in the 21st century? The journey is winding: from the undergrowth to
      the hashtag, from found objects to parallel realities, from the grotesque to the
      mythological, from financial investment to the importation of systems of oppression. But
      don't worry: each chapter will offer you self-study, visual appreciation and reflection
      tools to question, rethink, resist or even join the art system! Say goodbye to passivity on
      the other side of the screen and join the rats’ attack. Share this plastic adventure with your
      whole family. `,
      'author': 'Verónica Gerber Bicecci',
      'download_text': {
        'text': 'Download full text here',
        'url': require('../assets/pdf/RATATTACK_en.pdf'),
      }
    },

  },
  'semblance': {
    'es': {
      'credits': [
        {
          title: 'Créditos exposición', sub: [
            { title: 'Coordinación: ', text: `Marcela Chao Ruiz` },
            { title: 'Acompañamiento: ', text: `Ilana Boltvinik` },
            { title: 'Montaje: ', text: `Juan Rosas, Carlos Barragán y Llamas a mí` },
            { title: 'Tutores: ', text: `Ilana Boltvinik, Virginia Colwell, Ana Gallardo, Veronica Gerber Bicecci, Galia Eibenschutz, Joaquín Segura, Benjamín Torres y Pablo Vargas Lugo` },
          ]
        },
        {
          title: 'Agradecimientos', sub: [
            {
              title: '', text: `La generación 2020 del Programa Educativo SOMA agradece a: 
          Sofía Carrillo, Cinthya García Leyva, Mauricio Alejo, Julio Garcia Murillo, Leslie Moody Castro, Jorge Satorre, Rita Ponce de León, Leonardo Aranda, Adriana Salazar, Violeta Celis, Richard Moszcka, Luis Felipe Fabre, Pia Camil,  Yutsil Cruz, Itala Schmelz, Dulce Chacón, Magali Lara, Perla Krauze, Erick Meyenberg, Arturo Hernández Alcázar, Ricardo Alzati, Balam Bartolomé, Javier Barrios, Casi Galería, 邹薇 (Vicky Zou), 卢嘉露 (Gloria Lu) , Yorely Valero, Natalia de la Rosa, Daniel Aguilar Ruvalcaba, Maria Juana Patricia Rubio, Intton Godelg, Thomas Becka, Kim Torres, Megan Marsh, Mabe Fratti, Rogelio Sosa, Yoshua Okón, Cuarentena Baking, Martin Velazquez, grabados Victor Hernández, Sebastián Vergara, Erandi Romero, Ana Luz Chamu, François Nobécourt Arras, V de Talleyrand-Perigord, Karla Kaplun, Angel Ramos Villegas, Norma Robles, Colaboradores del Atlas de Letras Turísticas, Biquini Wax EPS, Alejandro Gómez Arias, Marco Solis y Artprice.com
          ` }
          ]
        },
        {
          title: 'Créditos sitio web', sub: [
            { title: '', text: `Agradecemos a Luis Vargas, Isabel Gil, Rodrigo Garrido, Ana Ximena Olvera, Pablo Pacheco, Iván Alberto García y al equipo de Aura Cultura por realizar la página web de Rat Attack.` },
            { title: 'Coordinación de la página: ', text: `Carolina Díaz y Marcela Chao Ruiz` },
            { title: 'Idea original: ', text: `Clemente Castor, Israel Urmeer y Samuel Nicolle ` },
          ]
        },
      ],
      'description': 'SOMA es una asociación civil sin fines de lucro en la Ciudad de México, que promueve el diálogo y la colaboración entre artistas y agentes culturales de diferentes contextos, disciplinas y generaciones. A través de sus programas se analizan colectivamente las consecuencias estéticas, políticas y sociales de la producción de arte.',
      'description_2': 'El Programa Educativo SOMA es una plataforma educativa con dos años de duración, para artistas interesados en continuar su formación y repensar su práctica. El programa busca generar un espacio para la discusión horizontal, la interacción colectiva y el pensamiento crítico. Esta plataforma está diseñada para estimular el diálogo entre artistas de diferentes nacionalidades y generaciones, así como especialistas de distintas disciplinas a través de cursos, talleres, sesiones de crítica y otros eventos públicos.',
      'sponsor_text': 'Producción nacional de artes visuales realizada con el estímulo fiscal del artículo 190 de la LISR (EFIARTES)'
    },
    'en': {
      'credits': [
        {
          title: 'Créditos exposición', sub: [
            { title: 'Coordinación: ', text: `Marcela Chao Ruiz` },
            { title: 'Acompañamiento: ', text: `Ilana Boltvinik` },
            { title: 'Montaje: ', text: `Juan Rosas, Carlos Barragán y Llamas a mí` },
            { title: 'Tutores: ', text: `Ilana Boltvinik, Virginia Colwell, Ana Gallardo, Veronica Gerber Bicecci, Galia Eibenschutz, Joaquín Segura, Benjamín Torres y Pablo Vargas Lugo` },
          ]
        },
        {
          title: 'Agradecimientos', sub: [
            {
              title: '', text: `La generación 2020 del Programa Educativo SOMA agradece a: 
          Sofía Carrillo, Cinthya García Leyva, Mauricio Alejo, Julio Garcia Murillo, Leslie Moody Castro, Jorge Satorre, Rita Ponce de León, Leonardo Aranda, Adriana Salazar, Violeta Celis, Richard Moszcka, Luis Felipe Fabre, Pia Camil,  Yutsil Cruz, Itala Schmelz, Dulce Chacón, Magali Lara, Perla Krauze, Erick Meyenberg, Arturo Hernández Alcázar, Ricardo Alzati, Balam Bartolomé, Javier Barrios, Casi Galería, 邹薇 (Vicky Zou), 卢嘉露 (Gloria Lu) , Yorely Valero, Natalia de la Rosa, Daniel Aguilar Ruvalcaba, Maria Juana Patricia Rubio, Intton Godelg, Thomas Becka, Kim Torres, Megan Marsh, Mabe Fratti, Rogelio Sosa, Yoshua Okón, Cuarentena Baking, Martin Velazquez, grabados Victor Hernández, Sebastián Vergara, Erandi Romero, Ana Luz Chamu, François Nobécourt Arras, V de Talleyrand-Perigord, Karla Kaplun, Angel Ramos Villegas, Norma Robles, Colaboradores del Atlas de Letras Turísticas, Biquini Wax EPS, Alejandro Gómez Arias, Marco Solis y Artprice.com
          ` }
          ]
        },
        {
          title: 'Créditos sitio web', sub: [
            { title: '', text: `Agradecemos a Luis Vargas, Isabel Gil, Rodrigo Garrido, Ana Ximena Olvera, Iván Alberto García, Pablo Pacheco y al equipo de Aura Cultura por realizar la página web de Rat Attack.` },
            { title: 'Coordinación de la página: ', text: `Carolina Díaz y Marcela Chao Ruiz` },
            { title: 'Idea original: ', text: `Clemente Castor, Israel Urmeer y Samuel Nicolle ` },
          ]
        },
      ],
      'description': 'SOMA es una asociación civil sin fines de lucro en la Ciudad de México, que promueve el diálogo y la colaboración entre artistas y agentes culturales de diferentes contextos, disciplinas y generaciones. A través de sus programas se analizan colectivamente las consecuencias estéticas, políticas y sociales de la producción de arte.',
      'description_2': 'El Programa Educativo SOMA es una plataforma educativa con dos años de duración, para artistas interesados en continuar su formación y repensar su práctica. El programa busca generar un espacio para la discusión horizontal, la interacción colectiva y el pensamiento crítico. Esta plataforma está diseñada para estimular el diálogo entre artistas de diferentes nacionalidades y generaciones, así como especialistas de distintas disciplinas a través de cursos, talleres, sesiones de crítica y otros eventos públicos.',
      'sponsor_text': 'Producción nacional de artes visuales realizada con el estímulo fiscal del artículo 190 de la LISR (EFIARTES)'
    }
  },
  'header': {
    'es': {
      'home': 'INICIO',
      'episodes': 'EPISODIOS',
      'public_program': 'PROGRAMA PÚBLICO',
      'appointment': 'AGENDA CITA',
      'expo': 'EXPOSICIÓN',
      'lang': 'ENGLISH'
    },
    'en': {
      'home': 'INICIO',
      'episodes': 'EPISODIOS',
      'public_program': 'PROGRAMA PÚBLICO',
      'appointment': 'AGENDA CITA',
      'expo': 'EXPOSICIÓN',
      'lang': 'ESPAÑOL'
    }
  },
}

export const langScheme = section => {
  let { lang } = store.getState().appState
  return key => {
    return langTexts[section][lang][key]
  }
}