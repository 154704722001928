import React from 'react'
import Background from '../../baseComponents/BackgroundImage'
import Header from '../../baseComponents/Header'
import { connect } from 'react-redux'
import BoxContainer from '../../baseComponents/BoxContainer'
import CirclePicture from '../../baseComponents/CirclePicture'
import ImageGallery from 'react-image-gallery'
import { getEpisode } from '../../services/episodes'
import { loaderTransition } from '../../utils/transitions'
import { getUrl } from '../../utils/urls'
import FloatingObjects from '../../baseComponents/FloatingObjects'
import AnimatedBackground from '../../baseComponents/AnimatedBackground'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import { accents } from '../../utils/accents'

import 'react-image-gallery/styles/css/image-gallery.css'

import './Episode.css'

const Episode = (props) => {
  let { id } = props.match.params
    , episode = getEpisode(id)
    , { lang } = props.appState

  const onStartTransition = () => {
  }

  const setEpisode = id => {
    loaderTransition(onStartTransition)
    setTimeout(() => {
      props.history.push(`${getUrl('episode')}/${id}`)
    }, 2400)
  }

  const onSetLink = link => {
    window.open(link, '_blank')
  }

  const renderItem = ({ original, alt }) => {
    return <BoxContainer className='no-borders-horizontal'>
      <TransformWrapper wheel={{ wheelEnabled: false }}>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <div className="tools">
              <button onClick={zoomIn}>+</button>
              <button onClick={zoomOut}>-</button>
              <button onClick={resetTransform}>x</button>
            </div>
            <TransformComponent>
              <div className='img-container'>
                <img src={original} alt={alt} />
              </div>
            </TransformComponent>
          </React.Fragment>
        )}


      </TransformWrapper>
    </BoxContainer>
  }

  const renderEmbedVideo = (id) => {
    if (id.search('vimeo:') !== -1) {
      id = id.replace('vimeo:', '')
      return <div className='video-container'>
        <iframe title={id} src={`https://player.vimeo.com/video/${id}`} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
      </div>
    }

    return <div className='video-container'>
      <iframe title={id} src={`https://www.youtube.com/embed/${id}?rel=0`} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
    </div>

  }


  if (!episode) return <Background>
    <AnimatedBackground />
    <div id='episode'>
      <Header onStartTransition={onStartTransition} time={2400} />
      <div className='container'>
        <BoxContainer className='card-desc'>
          <h1 className='title title-big center-text'>404</h1>
        </BoxContainer>
      </div>
    </div>
  </Background>

  let images = episode.media.images.map((img, index) => ({ original: img, thumbnail: img, alt: `${id}_${index}` }))

  return <Background>
    <AnimatedBackground />
    <div id='episode'>
      <Header onStartTransition={onStartTransition} time={2400} />
      <div className='container'>
        <FloatingObjects />
        <BoxContainer className='card-desc'>
          <h1 className='title'>{episode[`title_${lang}`]}</h1>
          <h2 className='artist-name accents' dangerouslySetInnerHTML={{ __html: accents(episode.artist) }}></h2>
          <div className='info-container'>

            {
              episode[`project_desc_${lang}`].map((paragraph, index) => <React.Fragment key={index}>
                <h4 className='paragraph-title'>{paragraph.title}</h4>
                <p className='paragraph-text'>{paragraph.text}</p>
              </React.Fragment>)
            }

            <img className='rat-image' src={episode.profilePic} alt={episode.artist} />
          </div>
        </BoxContainer>

        <div className="links_container">
          {
            episode[`links_${lang}`] &&
            episode[`links_${lang}`].map(item => {
              let paragraphs = item.texts.map(p => <p key={p} className='paragraph-text'>{p}</p>)
              return <React.Fragment>
                {paragraphs}
                <p onClick={() => onSetLink(item.link)} className='link'>{item.textLink}</p>
              </React.Fragment>
            })
          }
        </div>

        {
          episode.youtube_video
            ? renderEmbedVideo(episode.youtube_video)
            : null
        }

        <ImageGallery items={images} renderItem={renderItem} showFullscreenButton={false} showPlayButton={false} />

        <BoxContainer className='card-desc'>
          <CirclePicture overflowHidden={episode.overflowHidden} className='profile-pic' image={episode.profilePic} alt={episode.artist} />
          <div className='spacer' />
          <h1 className='title center-text'>{episode.artist}</h1>
          {
            episode[`bio_${lang}`].map((paragraph, index) => <React.Fragment key={index}>
              <h4 className='paragraph-title' dangerouslySetInnerHTML={{ __html: paragraph.title }} />
              <p className='paragraph-text' dangerouslySetInnerHTML={{ __html: paragraph.text }} />
            </React.Fragment>)
          }
        </BoxContainer>



        <div className='arrow-buttons'>
          <span onClick={() => setEpisode(episode.prev_episode)}>{'<< Previo'}</span>
          <span onClick={() => setEpisode(episode.next_episode)}>{'Siguiente >>'}</span>
        </div>
      </div>
    </div>
  </Background >
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(Episode)
