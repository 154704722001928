import React from 'react'
import bg from '../assets/images/bg_blue_rats.jpg'

import './BackgroundImage.css'

const BackgroundImage = ({ children = null, background = bg }) => {

  return <div className='background-image' style={{ background: `url("${bg}")`, minHeight: '100%', overflowX: 'auto' }}>
    <div className='background-image-container'>
      {children}
    </div>
  </div>
}

export default BackgroundImage