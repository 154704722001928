import React from 'react'
import { connect } from 'react-redux'

import glue from '../assets/images/glue.png'
import brush from '../assets/images/brush.png'
import cranyon from '../assets/images/cranyon.png'
import cranyon_02 from '../assets/images/cranyon_02.png'
import scissors from '../assets/images/scissors.png'
import scissors_2 from '../assets/images/scissors_02.png'
import bg from '../assets/images/bg_blue_rats.jpg'
import './Loader.css'

const Loader = ({ appState: { isLoaderActive }, dispatch }) => {
  return isLoaderActive
    ? <div id="transition_animation_container">
      <div id="glue" className="item">
        <img src={glue} alt='glue' />
      </div>
      <div id="brush" className="item">
        <img src={brush} alt='brush' />
      </div>
      <div id="cranyon" className="item">
        <img src={cranyon} alt='crayon' />
      </div>
      <div id="cranyon_02" className="item">
        <img src={cranyon_02} alt='crayon_02' />
      </div>
      <div id="scissors" className="item">
        <img id="scissors_1" src={scissors} alt='scissors_open' />
        <img id="scissors_2" src={scissors_2} alt='scissors_closed' />
      </div>
      <div id="background_transition">
        <div id="background_transition_image" style={{ background: `url("${bg}")` }} />
      </div>
    </div>
    : null
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(Loader)
