import React, { useEffect } from 'react'
import Background from '../../baseComponents/BackgroundImage'
import Header from '../../baseComponents/Header'
import Logo from '../../baseComponents/Logo'
import Popup from '../../baseComponents/Popup'
import Ticker from '../../baseComponents/Ticker'
import AnimatedBackground from '../../baseComponents/AnimatedBackground'

import { getEpisodes } from '../../services/episodes'
import { getUrl } from '../../utils/urls'
import { loaderTransition } from '../../utils/transitions'
import { langScheme } from '../../utils/langResources'
import { accents } from '../../utils/accents'

import splat_small from '../../assets/images/splat_small.png'
import sponsor_aura from '../../assets/images/sponsors/aura.png'
import sponsor_soma from '../../assets/images/sponsors/soma.png'
import sponsor_efiartes from '../../assets/images/sponsors/efiartes.png'

import './Home.css'

const pdt360DegViewer = window.pdt360DegViewer

const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i)
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i)
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i)
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i)
  },
  any: function () {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows())
  }
}

const Home = props => {

  const episodes = getEpisodes()
  const texts = langScheme('home')

  const onSetPage = url => {
    loaderTransition(onStartTransition)
    setTimeout(() => {
      props.history.push(url)
    }, 2400)
  }

  const onStartTransition = () => {

  }

  useEffect(() => {
    if (isMobile.any())
      pdt360DegViewer('rats_container', 82, './img/rats/rats_', 'png', false, true, false, false, false, false, false)
    else
      pdt360DegViewer('rats_container', 82, './img/rats/rats_', 'png', false, false, false, true, false, false, false)
  }, [])

  const onDonwload = () => {
    window.open(texts('pdf'), '_blank')
  }
  const onDonwloadSheet = () => {
    window.open(texts('pdf2'), '_blank')
  }
  return <React.Fragment>
    <Popup />
    <Background>
      <AnimatedBackground />
      <div id='home' className="section">
        <Header hideLogo onStartTransition={onStartTransition} time={2400} />
        <div className='container'>
          <div id="rats_container"></div>
          <div id="dummy"></div>
          <Logo />
          <div id='artist' className=''>
            {
              episodes.map((episode, index) => <React.Fragment key={episode.id}>
                <span
                  onClick={() => onSetPage(`${getUrl('episode')}/${episode.id}`)}
                  className='artist-name font_stroke accents'
                  dangerouslySetInnerHTML={{ __html: accents(episode.artist) }}

                ></span>
                {
                  index === episodes.length - 1
                    ? null
                    : <span className='artist-name'> </span>
                }
              </React.Fragment>)
            }
          </div>
          <div className='btns-container'>
            <div className="info_sheet" onClick={onDonwloadSheet}>
              <img src={splat_small} alt='splat' />
              <div className="title"><span>PUBLICACIÓN</span></div>
            </div>
            <div className="info_sheet" onClick={onDonwload}>
              <img src={splat_small} alt='splat' />
              <div className="title"><span>HOJA DE SALA</span></div>
            </div>
          </div>
          <Ticker />
          <div id="home_sponsors">
            <img src={sponsor_aura} alt="AURA" />
            <img src={sponsor_soma} alt="SOMA" />
            <img src={sponsor_efiartes} alt="Efiartes" />
          </div>
        </div>
      </div>
    </Background>
  </React.Fragment>
}


export default Home