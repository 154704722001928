const routes = {
  'home': {
    url: '/',
  },
  'appointment': {
    url: '/appointment'
  },
  'episode': {
    url: '/episode'
  },
  'episodes': {
    url: '/episodes'
  },
  'public-program': {
    url: '/public-program'
  },
  'semblance': {
    url: '/semblance'
  },
  'privacy': {
    url: '/aviso-de-privacidad'
  },
  'expo': {
    url: '/exposicion'
  }
}

export const getUrl = routeName => {
  let route = routes[routeName]
  return route ? route.url : '/'
}
