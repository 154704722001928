import React, { useState } from 'react'
import { langScheme } from '../../../utils/langResources'
import BoxContainer from '../../../baseComponents/BoxContainer'
import axios from 'axios'

const Form = ({ dates, onSuccess = () => null, setIsModalVisible }) => {
  const [name, setName] = useState('')
    , [email, setEmail] = useState('')
    , [phone, setPhone] = useState('')
    , [dateSelected, setDateSelected] = useState('')
    , [hour, setHour] = useState('13hrs')
    , [tickets, setTickets] = useState(1)
    , [availablePlaces, setAvailablePlaces] = useState(1)
    , [guest, setGuest] = useState([])
    , [error, setError] = useState('')
    , [ticketsError, setTicketsError] = useState('')
    , [comment, setComment] = useState('')
    , [isSending, setIsSending] = useState(false)
    , texts = langScheme('appointment')

  const onSubmit = async (e) => {
    e.preventDefault();
    setError('')
    if (!name.length || !email.length || !phone.length || !dateSelected.length)
      return setError('empty_field');
    // eslint-disable-next-line 
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) return setError('wrong_email')
    // eslint-disable-next-line 
    const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
    if (!phoneRegex.test(phone)) return setError('wrong_phone_format')

    let emptyGuest = guest.filter(text => !text.trim().length)

    if (emptyGuest.length) return setError('empty_guest_fields')

    if (ticketsError.length) return setError('tickets_error')

    let payload = { name, email, phone, date: dateSelected, guest, comment, hour }
    setIsSending(true)
    axios({
      url: 'https://us-central1-rat-attack-aed68.cloudfunctions.net/addAttendant',
      method: 'post',
      headers: { 'token': 'tfLdV5azziFCNJGqzooY', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'Content-Type' },
      data: payload
    })
      .then(async (res) => {
        if (!res.data.ErrorCode) {
          await axios({
            url: 'https://rat-attact.herokuapp.com/api/sent_email',
            method: 'post',
            headers: { 'token': 'tfLdV5azziFCNJGqzooY', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'Content-Type' },
            data: payload
          })
          onSuccess()
        } else {
          setIsSending(false)
          setError(res.data.ErrorCode)
        }
      })
      .catch(err => console.log(err))
  }

  const onTypeName = e => { setName(e.target.value) }
    , onTypeEmail = e => { setEmail(e.target.value) }
    , onTypePhone = e => { setPhone(e.target.value) }
    , onSelectDate = (date, availablePlaces, hour) => { setDateSelected(date); setAvailablePlaces(availablePlaces); setHour(`${hour}hrs`) }
    , onTypeComment = (e) => { setComment(e.target.value) }
    , onTypeAmountOfTickets = e => {
      setTicketsError('')
      let { value } = e.target
      setTickets(value)
      if (Number.isNaN(value)) return setTicketsError('not_a_number')
      else if (Number(value) < 1) return setTicketsError('less_than_zero')
      else if (Number(value) > availablePlaces) return setTicketsError('more_than')
      else if (!Number.isInteger(Number(value))) return setTicketsError('not_a_number')

      if (Number(value) > 1) setGuest(new Array(Number(value - 1)).fill(''))
      else setGuest([])
    }
    , onSetGuestName = (text, index) => {
      const list = Array.from(guest)
      list[index] = text
      setGuest([])
      setGuest(list)
    }

  return <React.Fragment>
    <div className='date-selector'>
      {
        dates.map((item) => {
          let { id, available_places, date, hour } = item
            , day = date.getDate()
            , month = texts(`m_${date.getMonth() + 1}`)
            , weekDay = texts(`d_${date.getDay()}`)
            , isSelected = id === dateSelected ? 'selected' : ''

          if (!available_places) return <div className='date disabled' key={id}>
            <span className='button-spacer'></span>
            <span>{weekDay} {day} {month}, {hour}hrs</span>
            <span className='button-spacer'></span>
          </div>
          return <div onClick={() => onSelectDate(id, available_places, hour)} className='date' key={id}>
            <span className={`radio-button ${isSelected}`}></span>
            <span>{weekDay} {day} {month}, {hour}hrs</span>
            <span className='button-spacer'></span>
          </div>
        })
      }
    </div>

    <BoxContainer className='form-container'>
      <form onSubmit={onSubmit}>
        <div className='form-field'>
          <label>{texts('name')}</label>
          <input type='text' value={name} onChange={onTypeName} />
        </div>
        <div className='form-field'>
          <label>{texts('email')}</label>
          <input type='email' value={email} onChange={onTypeEmail} />
        </div>

        <div className='form-field'>
          <label>{texts('amout_of_tickets')}</label>
          <input type='number' min={1} max={availablePlaces} value={tickets} onChange={onTypeAmountOfTickets} />
        </div>
        {
          guest.map((guestName, index) => <React.Fragment key={index}>
            <div className='form-field' key={index}>
              <label>{`${texts('guest')} ${index + 1}`}</label>
              <input type='text' value={guestName} onChange={(e) => onSetGuestName(e.target.value, index)} />
            </div>
          </React.Fragment>)
        }

        <div className='form-field'>
          <label>{texts('phone')}</label>
          <input type='phone' value={phone} onChange={onTypePhone} />
        </div>

        <div className='form-field'>
          <label>{texts('comment')}</label>
          <textarea type='text' value={comment} onChange={onTypeComment} />
        </div>

        {
          isSending
            ? <p>Enviando...</p>
            : <button onClick={onSubmit}>
              Enviar
              </button>
        }
        <h3>Al registrarte aceptas nuestro <span
          onClick={() => { setIsModalVisible(true) }}
          className='link link-color'>Aviso de privacidad</span></h3>
        <h3>{texts(ticketsError)}</h3>
        <h3>{texts(error)}</h3>
      </form>
    </BoxContainer>
  </React.Fragment>

}

export default Form