export const loaderTransition = (cb = () => null) => {
  if (typeof cb === 'function') cb()

  var animation_type = "transition_1";

  //SHOW TRANSITION CONTAINER
  document.getElementById("transition_animation_container").classList.add("animate")

  //BRUSH
  document.getElementById("brush").classList.add(animation_type)
  setTimeout(function () { document.getElementById("brush").classList.add("animate") }, 10)

  //SCISSORS
  document.getElementById("scissors").classList.add(animation_type)
  setTimeout(function () { document.getElementById("scissors").classList.add("animate") }, 10)

  //cranyon
  document.getElementById("cranyon").classList.add(animation_type)
  setTimeout(function () { document.getElementById("cranyon").classList.add("animate") }, 700)

  //cranyon2
  document.getElementById("cranyon_02").classList.add(animation_type)
  setTimeout(function () { document.getElementById("cranyon_02").classList.add("animate") }, 400)

  //glue
  document.getElementById("glue").classList.add(animation_type)
  setTimeout(function () { document.getElementById("glue").classList.add("animate") }, 1200)

  //background
  document.getElementById("background_transition").classList.add(animation_type)
  setTimeout(function () { document.getElementById("background_transition").classList.add("in") }, 1500)
  setTimeout(function () { document.getElementById("background_transition").classList.add("out") }, 2500)

  //HIDE ALL ELEMENTS
  setTimeout(function () {
    document.getElementById("transition_animation_container").classList.remove("animate")
    document.getElementById("brush").classList.remove(animation_type)
    document.getElementById("brush").classList.remove("animate")
    document.getElementById("scissors").classList.remove(animation_type)
    document.getElementById("scissors").classList.remove("animate")
    document.getElementById("cranyon").classList.remove(animation_type)
    document.getElementById("cranyon").classList.remove("animate")
    document.getElementById("cranyon_02").classList.remove(animation_type)
    document.getElementById("cranyon_02").classList.remove("animate")
    document.getElementById("glue").classList.remove(animation_type)
    document.getElementById("glue").classList.remove("animate")
    document.getElementById("background_transition").classList.remove("in")
    document.getElementById("background_transition").classList.remove("out")
  }, 3400);

}