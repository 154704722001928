import React from 'react'
import Background from '../../baseComponents/BackgroundImage'
import Header from '../../baseComponents/Header'
import FloatingObjects from '../../baseComponents/FloatingObjects'
import AnimatedBackground from '../../baseComponents/AnimatedBackground'
import { langScheme } from '../../utils/langResources'
import soma_logo from '../../assets/images/SOMA.png'
import sponsor_tajo from '../../assets/images/sponsors/tajo.png'
import sponsor_axa from '../../assets/images/sponsors/axa.png'
import sponsor_aura from '../../assets/images/sponsors/aura.png'
import sponsor_soma from '../../assets/images/sponsors/soma.png'
import sponsor_efiartes from '../../assets/images/sponsors/efiartes.png'

import './Semblance.css'

const Semblance = props => {
  const texts = langScheme('semblance')
    , onStartTransition = () => {
    }
  return <Background>
    <AnimatedBackground />
    <div id="semblance">
      <Header onStartTransition={onStartTransition} time={2400} />
      <div className='container'>
        <FloatingObjects />
        <div className='content'>
          <div className='description-container'>
            <div id="soma_logo" >
              <img src={soma_logo} alt="SOMA logotipo" />
            </div>
            <p className='description'>
              {texts('description')}
            </p>
            <p className='description'>
              {texts('description_2')}
            </p>


            {
              texts('credits').map((block, index) => {
                return <React.Fragment key={index}>
                  <h3 className='title-text small-title'>{block.title}</h3>
                  {
                    block.sub.map((paragraph, subIndex) => {
                      return <p key={subIndex} className='description small-text'><b>{paragraph.title}</b>{paragraph.text}</p>
                    })
                  }
                </React.Fragment>
              })
            }










          </div>
          <div id="sponsors_container">
            <img src={sponsor_tajo} alt="Tajo" />
            <img src={sponsor_axa} alt="Axa" />
            <img src={sponsor_aura} alt="AURA" />
            <img src={sponsor_soma} alt="SOMA" />
            <img src={sponsor_efiartes} alt="Efiartes" />
            <h4 className="title">{texts('sponsor_text')}</h4>
          </div>
        </div>
      </div>
    </div>
  </Background>
}

export default Semblance