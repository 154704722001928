import { combineReducers, createStore } from 'redux'

import appStateReducer from './reducers/appState'

const reducers = combineReducers({
  appState: appStateReducer
})

const store = createStore(reducers)

export default store