const events = {
  'event-1': {
    'title_es': 'Viaje al fin del mundo sin retorno',
    'title_en': 'Viaje al fin del mundo sin retorno',
    author: 'Itala Schmelz',
    'date_es': '2 de septiembre a las 8:30 p.m. ',
    'date_en': '2 de septiembre a las 8:30 p.m. ',
    'semblance_es': [
      {
        title: '',
        text: `Itala Schmelz (Ciudad de México, 1968) es filósofa formada en la Universidad Nacional Autónoma de México (UNAM). Fue directora de la Sala de Arte Público Siqueiros (SAPS) (2001-2007), del Museo de Arte Carrillo Gil (MACG) (2007-2011) y del Centro de la Imagen (2013-2018). Creadora de “El futuro más acá” (2003-2007), primer festival dedicado al cine mexicano de ciencia ficción. Seleccionada curadora del pabellón de México en la 55 Bienal de Venecia (2013).`
      }
    ],
    'semblance_en': [
      {
        title: '',
        text: `Itala Schmelz (Ciudad de México, 1968) es filósofa formada en la Universidad Nacional Autónoma de México (UNAM). Fue directora de la Sala de Arte Público Siqueiros (SAPS) (2001-2007), del Museo de Arte Carrillo Gil (MACG) (2007-2011) y del Centro de la Imagen (2013-2018). Creadora de “El futuro más acá” (2003-2007), primer festival dedicado al cine mexicano de ciencia ficción. Seleccionada curadora del pabellón de México en la 55 Bienal de Venecia (2013).`
      }
    ],
    'abstract_es': [
      {
        title: '', text: `
        Itala Schmelz revisará algunos títulos de la cinematografía y la literatura nacional, para analizar las crónicas de una catástrofe ya anunciada. Si bien, se abordará la estética del fin del mundo desde el contexto específico de la CDMX, se encontrarán con la idea de ciclos, donde tras el fin de un mundo, comienza un nuevo mundo. 
        `
      },
    ],
    'abstract_en': [
      {
        title: '', text: `
        Itala Schmelz revisará algunos títulos de la cinematografía y la literatura nacional, para analizar las crónicas de una catástrofe ya anunciada. Si bien, se abordará la estética del fin del mundo desde el contexto específico de la CDMX, se encontrarán con la idea de ciclos, donde tras el fin de un mundo, comienza un nuevo mundo. 
        `
      },
    ],
    banner: require('../assets/images/events/programa_publico_itala_schmelz.jpg'),
    videoURL: '3-_hVdIEfHM',
  },
  'event-2': {
    'title_es': '¡Recorrido virtual de la exposición colectiva Rat Attack!',
    'title_en': '¡Recorrido virtual de la exposición colectiva Rat Attack!',
    author: 'Daniel Aguilar Ruvalcaba ',
    'date_es': '9 de septiembre a las 8:30 p.m. .',
    'date_en': '9 de septiembre a las 8:30 p.m. .',
    'semblance_es': [
      {
        title: '', text: `Daniel Aguilar Ruvalcaba (León, 1988) es un charlatán del Bajío mexicano que, por ahora, dice jalar como artista residente en la Rijksakademie en los Países Bajos. Cursó el Programa Educativo de SOMA y 4 licenciaturas truncas en la Universidad de Guanajuato. En 2018 fue galardonado con un doctorado honoris causa en negocios y lácteos por el Mercado Echeveste.`
      }
    ],
    'semblance_en': [
      {
        title: '', text: `Daniel Aguilar Ruvalcaba (León, 1988) es un charlatán del Bajío mexicano que, por ahora, dice jalar como artista residente en la Rijksakademie en los Países Bajos. Cursó el Programa Educativo de SOMA y 4 licenciaturas truncas en la Universidad de Guanajuato. En 2018 fue galardonado con un doctorado honoris causa en negocios y lácteos por el Mercado Echeveste.`
      }
    ],
    'abstract_es': [
      {
        title: '', text: `Daniel Aguilar Ruvalcaba realizará un recorrido virtual de la exposición colectiva Rat Attack desde un poema de 90 minutos, teniendo como referencia una serie de enigmáticas imágenes que lxs artistas seleccionaron y compartieron en una solitaria carpeta de gugul draif.
        `
      },
    ],
    'abstract_en': [
      {
        title: '', text: `Daniel Aguilar Ruvalcaba realizará un recorrido virtual de la exposición colectiva Rat Attack desde un poema de 90 minutos, teniendo como referencia una serie de enigmáticas imágenes que lxs artistas seleccionaron y compartieron en una solitaria carpeta de gugul draif.
        `
      },
    ],
    banner: require('../assets/images/events/programa_publico_daniel_aguilar.jpg'),
    videoURL: '3Gwxbfsje8Q'
  },
  'event-3': {
    'title_es': 'Word, PDF´s y JPG´s',
    'title_en': 'Word, PDF´s y JPG´s',
    author: 'Verónica Gerber Bicecci, Alfonso Santiago',
    'date_es': '17 de septiembre a las 8:30 p.m. ',
    'date_en': '17 de septiembre a las 8:30 p.m. ',
    'semblance_es': [
      {
        title: '', text: `
        Verónica Gerber Bicecci es una artista visual que escribe. Ha publicado los libros: Mudanza, Conjunto vacío (premio internacional Aura Estrada, premio Cálamo Otra mirada, traducido al inglés por Christina MacSweeney), Palabras migrantes (bilingüe; traducido y comentado por Christina MacSweeney). Algunos de sus proyectos e intervenciones visuales más recientes son: el vocabulario b en muca Roma, Ciudad de México; lamaquinadistopica.xyz en el Museo Arte Abstracto Manuel Felguérez, Zacatecas.
        `
      },
      {
        title: '', text: `
        Alfonso Santiago es artista y diseñador editorial por la Facultad de Artes y Diseño. Ha colaborado en proyectos educativos en la Fundación Jumex, el Museo Nacional de Arte y Casa del Lago. Obtuvo la beca Jóvenes Creadores del FONCA en 2015.  Formó parte del Seminario de Producción Fotográfica del Centro de la Imagen en 2017 y del programa Triple Canopy’s Publication Intensive en Los Ángeles en 2018. Actualmente coordina el proyecto editorial de ESPAC.
        `
      },
      {
        title: '', text: `
        Rodrigo Garrido es un artista que trabaja con inteligencia artificial, escultura y mundos virtuales. En su obra, intenta entender a la especie humana, su mente, sus invenciones y hacia dónde vamos. Para esto, Rodrigo desarrolla inteligencia artificial que propone soluciones a problemáticas actuales, simulaciones que presenten alternativas a la realidad que vivimos y esculturas que reflejan hasta dónde hemos llegado como especie.
        En 2008 fundó Alucina, estudio de tecnología, en 2016 se unió como socio en AURA Cultura, en donde lidera el área de innovación y en 2017 fundó Passenger, estudio de videojuegos.
        Rodrigo es Ingeniero en Cibernética por la ULSA, ha cursado diversos seminarios en Campus Expandido del MUAC en la UNAM, workshops en el IIE de la UNAM y bootcamps en TechBA Seattle y Silicon Valley.
        `
      }
    ],
    'semblance_en': [
      {
        title: '', text: `
        Verónica Gerber Bicecci es una artista visual que escribe. Ha publicado los libros: Mudanza, Conjunto vacío (premio internacional Aura Estrada, premio Cálamo Otra mirada, traducido al inglés por Christina MacSweeney), Palabras migrantes (bilingüe; traducido y comentado por Christina MacSweeney). Algunos de sus proyectos e intervenciones visuales más recientes son: el vocabulario b en muca Roma, Ciudad de México; lamaquinadistopica.xyz en el Museo Arte Abstracto Manuel Felguérez, Zacatecas.
        `
      },
      {
        title: '', text: `
        Alfonso Santiago es artista y diseñador editorial por la Facultad de Artes y Diseño. Ha colaborado en proyectos educativos en la Fundación Jumex, el Museo Nacional de Arte y Casa del Lago. Obtuvo la beca Jóvenes Creadores del FONCA en 2015.  Formó parte del Seminario de Producción Fotográfica del Centro de la Imagen en 2017 y del programa Triple Canopy’s Publication Intensive en Los Ángeles en 2018. Actualmente coordina el proyecto editorial de ESPAC.
        `
      },
      {
        title: '', text: `
        Rodrigo Garrido es un artista que trabaja con inteligencia artificial, escultura y mundos virtuales. En su obra, intenta entender a la especie humana, su mente, sus invenciones y hacia dónde vamos. Para esto, Rodrigo desarrolla inteligencia artificial que propone soluciones a problemáticas actuales, simulaciones que presenten alternativas a la realidad que vivimos y esculturas que reflejan hasta dónde hemos llegado como especie.
        En 2008 fundó Alucina, estudio de tecnología, en 2016 se unió como socio en AURA Cultura, en donde lidera el área de innovación y en 2017 fundó Passenger, estudio de videojuegos.
        Rodrigo es Ingeniero en Cibernética por la ULSA, ha cursado diversos seminarios en Campus Expandido del MUAC en la UNAM, workshops en el IIE de la UNAM y bootcamps en TechBA Seattle y Silicon Valley.
        `
      }
    ],
    'abstract_es': [
      {
        title: '', text: `¿Cómo se suman los Word, PDFs y JPEGs a las exposiciones en pandemia?
        Veronica Gerber, Alfonso Santiago y Rodrigo Garrido, hablarán del proceso de escritura, edición y programación como parte de otros momentos de circulación de Rat Attack, en un mundo que ahora más que antes consume contenidos por internet.`
      },
    ],
    'abstract_en': [
      {
        title: '', text: `¿Cómo se suman los Word, PDFs y JPEGs a las exposiciones en pandemia?
        Veronica Gerber, Alfonso Santiago y Rodrigo Garrido, hablarán del proceso de escritura, edición y programación como parte de otros momentos de circulación de Rat Attack, en un mundo que ahora más que antes consume contenidos por internet.`
      },
    ],
    banner: require('../assets/images/programa_publico/programa_publico_Veronica.jpg'),
    videoURL: 'MSe263jwd7s',
  },
  'event-4': {
    'title_es': 'La Burbuja Tv: Top Ten',
    'title_en': 'La Burbuja Tv: Top Ten',
    author: 'Natalia de la Rosa y Julio García Murillo',
    'date_es': '23 de septiembre a las 8:30 p.m. ',
    'date_en': '23 de septiembre a las 8:30 p.m. ',
    'semblance_es': [
      {
        title: '', text: `
        Natalia de la Rosa (Ciudad de México, 1983) obtuvo su doctorado en Historia del Arte por la Universidad Nacional Autónoma de México. Sus intereses cubren el arte moderno y contemporáneo en México, el muralismo, el arte público y la teoría de la vanguardia en América Latina. Su trabajo establece un análisis en torno a las relaciones entre el arte, la política y la economía, y sus acercamientos plantean una forma de repensar redes existentes entre artistas, críticos, teóricos e instituciones culturales en el hemisferio. 
        `
      },
      {
        title: '', text: `
        Julio García Murillo (Ciudad de México, 1984) estudió la licenciatura en Filosofía en la Universidad La Salle (ULSA) y una maestría en Historia del Arte con énfasis en Estudios Curatoriales en la Universidad Nacional Autónoma de México (UNAM) para la que actualmente ﬁnaliza una investigación sobre Grupo Proceso Pentágono. Su trabajo se desplaza entre la investigación, la producción artística, la edición y la curaduría mediante la exploración de los “no objetualismos”, la crítica institucional y la museología radical.
        `
      }
    ],
    'semblance_en': [
      {
        title: '', text: `
        Natalia de la Rosa (Ciudad de México, 1983) obtuvo su doctorado en Historia del Arte por la Universidad Nacional Autónoma de México. Sus intereses cubren el arte moderno y contemporáneo en México, el muralismo, el arte público y la teoría de la vanguardia en América Latina. Su trabajo establece un análisis en torno a las relaciones entre el arte, la política y la economía, y sus acercamientos plantean una forma de repensar redes existentes entre artistas, críticos, teóricos e instituciones culturales en el hemisferio. Fue curadora de la colección del Museo de Arte Moderno entre 2014 y 2016. 
        `
      },
      {
        title: '', text: `
        Julio García Murillo (Ciudad de México, 1984) estudió la licenciatura en Filosofía en la Universidad La Salle (ULSA) y una maestría en Historia del Arte con énfasis en Estudios Curatoriales en la Universidad Nacional Autónoma de México (UNAM) para la que actualmente ﬁnaliza una investigación sobre Grupo Proceso Pentágono. Su trabajo se desplaza entre la investigación, la producción artística, la edición y la curaduría mediante la exploración de los “no objetualismos”, la crítica institucional y la museología radical. Ha presentado su trabajo como investigador en congresos y simposios nacionales e internacionales. Actualmente es coordinador de la Sala de Colecciones Universitarias del Centro Cultural Universitario Tlatelolco de la UNAM y profesor de las asignaturas de Historia del Arte y Curaduría en la Escuela Nacional de Pintura, Escultura y Grabado “La Esmeralda”
        `
      }
    ],
    'abstract_es': [
      {
        title: '', text: `
        En una emisión de La Burbuja Tv, Natalia de la Rosa y Julio García Murillo presentará un Top Ten de verdades y mentiras históricas desde una desenfrenada reproducción comentada de fragmentos de videos de arte, textos, libros, chismes, videoclips, memes, talk-shows y fotos de la cultura y la historia del arte mexicano (y un poquito más acá y allá). Estos materiales, todos disponibles en el gran basurero digital,  darán entrada a chistes, disparates y apuntes aburridamente teóricos. El formato, nostálgico y versátil, coqueteará con referentes y algunas obras de Rat Attack. 
        `
      },
    ],
    'abstract_en': [
      {
        title: '', text: `
        En una emisión de La Burbuja Tv, Natalia de la Rosa y Julio García Murillo presentará un Top Ten de verdades y mentiras históricas desde una desenfrenada reproducción comentada de fragmentos de videos de arte, textos, libros, chismes, videoclips, memes, talk-shows y fotos de la cultura y la historia del arte mexicano (y un poquito más acá y allá). Estos materiales, todos disponibles en el gran basurero digital,  darán entrada a chistes, disparates y apuntes aburridamente teóricos. El formato, nostálgico y versátil, coqueteará con referentes y algunas obras de Rat Attack. 
        `
      },
    ],
    banner: require('../assets/images/programa_publico/programa_publico_natalia.jpg'),
    videoURL: 'Ky5EmIA4sV4'
  },
  'event-10': {
    'title_es': 'Manual del buen comportamiento ',
    'title_en': 'Manual del buen comportamiento ',
    author: 'El Pinche Barrendero',
    'date_es': '30 de septiembre a las 8:30 p.m. ',
    'date_en': '30 de septiembre a las 8:30 p.m. ',
    'semblance_es': [
      {
        title: '', text: `Miriam Gómez, alias: El Pinche Barrendero (Ciudad de México, 1991) estudió fotografía en la Universidad Veracruzana, cursó el diplomado de artes visuales CaSA y desde hace 7 años realiza publicaciones de manera independiente. Su trabajo se enfoca principalmente en la creación de personajes para hablar sobre comportamiento humano, retratando a una sociedad en decadencia. Su trabajo ha sido presentado en la Ciudad de México, Xalapa, Oaxaca, Córdoba y Francia, fue parte de la tercera bienal de Veracruz , recientemente en la plataforma PICS del Centro de la Imagen y una exposición individual en Estudio Marte CDMX. 
        `
      },
    ],
    'semblance_en': [
      {
        title: '', text: `Miriam Gómez, alias: El Pinche Barrendero (Ciudad de México, 1991) estudió fotografía en la Universidad Veracruzana, cursó el diplomado de artes visuales CaSA y desde hace 7 años realiza publicaciones de manera independiente. Su trabajo se enfoca principalmente en la creación de personajes para hablar sobre comportamiento humano, retratando a una sociedad en decadencia. Su trabajo ha sido presentado en la Ciudad de México, Xalapa, Oaxaca, Córdoba y Francia, fue parte de la tercera bienal de Veracruz , recientemente en la plataforma PICS del Centro de la Imagen y una exposición individual en Estudio Marte CDMX. 
        `
      },
    ],
    'abstract_es': [
      {
        title: '', text: `El Pinche Barrendero, a través del personaje del pinche Miguelito transmitirá el manual del buen  comportamiento  hablando de manera no impositiva pero sí de manera egocéntrica sobre la deconstrucción del "ser egoísta" de una sociedad en decadencia, al vez que se pasea por exposición Rat Attack.
        `
      },
    ],
    'abstract_en': [
      {
        title: '', text: `El Pinche Barrendero, a través del personaje del pinche Miguelito transmitirá el manual del buen  comportamiento  hablando de manera no impositiva pero sí de manera egocéntrica sobre la deconstrucción del "ser egoísta" de una sociedad en decadencia, al vez que se pasea por exposición Rat Attack.
        `
      },
    ],
    banner: require('../assets/images/events/programa_publico_barrendero.jpg'),
    videoURL: 'GgpW3CtvOOg'
  },
  'event-6': {
    'title_es': 'Mi casa soy yo',
    'title_en': 'Mi casa soy yo',
    author: 'Alberto Perera (TALLER)',
    'date_es': '07 de Octubre a las 8:30 p.m. ',
    'date_en': '07 de Octubre a las 8:30 p.m. ',
    'semblance_es': [
      {
        title: '', text: `
        Alberto Perera (Barcelona, 1989) Estudió Diseño de Moda en BAU, Escola Superior de
        Disseny y participó en "Posar el cos. Activismes feministes i queer" en CCCB. (Barcelona).
        Ha presentado su trabajo e impartido talleres en Aeromoto Biblioteca Pública, Nottingham
        Contemporary (UK), Centro Cultural Border, Centro Cultural España de México, Errerrede,
        ENPEG “La Esmeralda”, entre otros. Ha realizado performance en lugares como Material Art Fair (Beverly’s) y aparecido en producciones teatrales en Museo Tamayo y Muestra Nacional de Teatro de México.

        `
      }
    ],
    'semblance_en': [
      {
        title: '', text: `
        Alberto Perera (Barcelona, 1989) Estudió Diseño de Moda en BAU, Escola Superior de
        Disseny y participó en "Posar el cos. Activismes feministes i queer" en CCCB. (Barcelona).
        Ha presentado su trabajo e impartido talleres en Aeromoto Biblioteca Pública, Nottingham
        Contemporary (UK), Centro Cultural Border, Centro Cultural España de México, Errerrede,
        ENPEG “La Esmeralda”, entre otros. Ha realizado performance en lugares como Material Art Fair (Beverly’s) y aparecido en producciones teatrales en Museo Tamayo y Muestra Nacional de Teatro de México.

        `
      }
    ],
    'abstract_es': [
      {
        title: '', text: `
        Alberto Perera presentará los resultados del taller "Mi casa soy yo", un ejercicio de autoexploración que impartió el 21 y 28 de septiembre dentro de Rat Attack. A través de material audiovisual y bibliográfico, se puso en marcha un diálogo en el que se compartieron experiencias y desarrollaron ejercicios prácticos, con el objetivo de conocer nuevas posibilidades de expresión y visibilización de nuestra identidad, minimizando los prejuicios interiorizados y trabajando todos éstos elementos desde la empatía. El objetivo principal del taller fue adquirir herramientas y abrir posibilidades que permitan multiplicar la visión que tenemos de nosotrxs mismxs y nuestra producción artística.
        `
      },
    ],
    'abstract_en': [
      {
        title: '', text: `
        Alberto Perera presentará los resultados del taller "Mi casa soy yo", un ejercicio de autoexploración que impartió el 21 y 28 de septiembre dentro de Rat Attack. A través de material audiovisual y bibliográfico, se puso en marcha un diálogo en el que se compartieron experiencias y desarrollaron ejercicios prácticos, con el objetivo de conocer nuevas posibilidades de expresión y visibilización de nuestra identidad, minimizando los prejuicios interiorizados y trabajando todos éstos elementos desde la empatía. El objetivo principal del taller fue adquirir herramientas y abrir posibilidades que permitan multiplicar la visión que tenemos de nosotrxs mismxs y nuestra producción artística.
        `
      },
    ],
    banner: require('../assets/images/programa_publico/programa_publico_Alberto_Perea.jpg'),
    videoURL: 'cfiYItocEes',
  },
  'event-7': {
    'title_es': 'Tienes Miedo',
    'title_en': 'Tienes Miedo',
    author: 'Bernardo Núñez Magdaleno',
    'date_es': '14 de Octubre a las 8:30 p.m. ',
    'date_en': '14 de Octubre a las 8:30 p.m. ',
    'semblance_es': [
      {
        title: '', text: `
        Bernardo Núñez Magdaleno (Tijuana-San Diego) es escritor y traductor, interesado en el tránsito entre lenguajes, territorios y sensibilidades. Maestro en Literatura Latinoamericana por la Universidad de California en San Diego. Miembro de Arte y Trabajo Bikini Wax EPS. Su trabajo se ha publicado en plataformas como Campo de relámpagos, Horizontal, Gato Negro Ediciones, OEI (Suecia) y La Tempestad.
        `
      }
    ],
    'semblance_en': [
      {
        title: '', text: `
        Bernardo Núñez Magdaleno (Tijuana-San Diego) es escritor y traductor, interesado en el tránsito entre lenguajes, territorios y sensibilidades. Maestro en Literatura Latinoamericana por la Universidad de California en San Diego. Miembro de Arte y Trabajo Bikini Wax EPS. Su trabajo se ha publicado en plataformas como Campo de relámpagos, Horizontal, Gato Negro Ediciones, OEI (Suecia) y La Tempestad.
        `
      }
    ],
    'abstract_es': [
      {
        title: '', text: `
        ¿Tienes miedo? ¿Qué es el miedo? ¿Qué/quién te da miedo? ¿Desde cuándo? ¿A qué suena/sabe el miedo? Bernardo Núñez Magdaleno hará una lectura y una interpretación de los temores más espeluznantes de Rat Attack,  ¿A qué le tememos los participantes? ¿Qué temen a través de nosotros? 
        `
      },
    ],
    'abstract_en': [
      {
        title: '', text: `
        ¿Tienes miedo? ¿Qué es el miedo? ¿Qué/quién te da miedo? ¿Desde cuándo? ¿A qué suena/sabe el miedo? Bernardo Núñez Magdaleno hará una lectura y una interpretación de los temores más espeluznantes de Rat Attack,  ¿A qué le tememos los participantes? ¿Qué temen a través de nosotros? 
        `
      },
    ],
    banner: require('../assets/images/events/1.jpeg'),
    videoURL: 'Ky5EmIA4sV4'
  },
  'event-5': {
    hideContainer: true,
    'title_es': '',
    'title_en': '',
    author: '',
    'date_es': '',
    'date_en': '',
    'semblance_es': [
      {
        title: '', text: ``
      }
    ],
    'semblance_en': [
      {
        title: '', text: ``
      }
    ],
    'abstract_es': [
      {
        title: 'Taller Mi casa soy yo', text: ``
      },
      {
        title: '', text: `Imparte Alberto Perera`
      },
      {
        title: '', text: `Dos sesiones: 21 y 28 de septiembre`
      },
      {
        title: '', text: `Horario: 12:00 a 2:00 pm`
      },
      {
        hasLink: true,
        title: '', text: `Como parte de las actividades públicas se impartirá el taller gratuito y en línea dirigido a personas con interés en las expresiones artísticas y procesos creativos en cualquier área.
        Cupo limitado a 15 personas. Para registrarse deberán llenar el siguiente formulario`,
        hyperlink: {
          link: 'https://forms.gle/UJqWF5ZnuhsW1Z4U9',
          keyText: 'formulario',
        }
      },
    ],
    'abstract_en': [
      {
        title: 'Taller Mi casa soy yo', text: ``
      },
      {
        title: '', text: `Imparte Alberto Perera`
      },
      {
        title: '', text: `Dos sesiones: 21 y 28 de septiembre`
      },
      {
        title: '', text: `Horario: 12:00 a 2:00 pm`
      },
      {
        hasLink: true,
        title: '', text: `Como parte de las actividades públicas se impartirá el taller gratuito y en línea dirigido a personas con interés en las expresiones artísticas y procesos creativos en cualquier área.
        Para registrarse deberán llenar el siguiente formulario. Cupo limitado a 15 personas. `,
        hyperlink: {
          link: 'https://forms.gle/UJqWF5ZnuhsW1Z4U9',
          keyText: 'formulario',
        }
      },
    ],
    banner: require('../assets/images/programa_publico/programa_publico_Alberto_Perea.jpg')
  },
}

export const getEvents = () => {
  let eventList = Object.keys(events).map(key => ({ ...events[key], id: key }))
  return eventList
}

/*

{
    'title_es': '',
    'title_en': '',
    author: '',
    'date_es': '',
    'date_en': '',
    'semblance_es': [
      {
        title: '', text: ``
      }
    ],
    'semblance_en': [
      {
        title: '', text: ``
      }
    ],
    'abstract_es': [
      {
        title: '', text: ``
      }
    ],
    'abstract_en': [
      {
        title: '', text: ``
      }
    ],
    banner: null
  },

*/