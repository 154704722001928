import React, { useEffect, useState } from 'react'
import Modal from './Modal'
import { withRouter } from 'react-router-dom'
import { loaderTransition } from '../utils/transitions'
import './Popup.css'

const popups = [
  /* 30 de septiembre */
  {
    title: 'Miércoles de SOMA ',
    description: `con Pinche Barrendero. 8:30 pm por YouTube Live `,
    btnTitle: '>> Aceptar <<',
    timeStart: [2020, 8, 30, 0, 0, 0, 0],
    timeEnd: [2020, 8, 30, 23, 59, 0, 0],
    externalLink: '',
    internalLink: ''
  },
  {
    title: 'Rat Attack',
    description: `Descarga la públicación de Rat Attack `,
    btnTitle: '>> Descargar <<',
    timeStart: [2020, 9, 2, 0, 0, 0, 0],
    timeEnd: [2020, 9, 2, 23, 59, 0, 0],
    externalLink: 'https://ratattack.somamexico.org/static/media/Hoja_Sala_SOMA_RatAttack.396ae351.pdf',
    internalLink: ''
  },
  {
    title: 'Miércoles de SOMA ',
    description: `con Alberto Perera. 8:30 pm por YouTube Live `,
    btnTitle: '>> Ir <<',
    timeStart: [2020, 9, 3, 0, 0, 0, 0],
    timeEnd: [2020, 9, 3, 23, 59, 0, 0],
    externalLink: 'https://www.youtube.com/watch?v=cfiYItocEes',
    internalLink: ''
  },
  {
    title: 'Miércoles de SOMA ',
    description: `con Alberto Perera. 8:30 pm por YouTube Live `,
    btnTitle: '>> IR <<',
    timeStart: [2020, 9, 4, 0, 0, 0, 0],
    timeEnd: [2020, 9, 4, 23, 59, 0, 0],
    externalLink: 'https://www.youtube.com/watch?v=cfiYItocEes',
    internalLink: ''
  },
  {
    title: '¡Últimas semanas! ',
    description: `Visita Rat Attack, únicamente con cita previa.`,
    btnTitle: '>> Agendar <<',
    timeStart: [2020, 9, 5, 0, 0, 0, 0],
    timeEnd: [2020, 9, 5, 23, 59, 0, 0],
    externalLink: '',
    internalLink: '/appointment'
  },
  {
    title: 'Miércoles de SOMA ',
    description: `con Alberto Perera. 8:30 pm por YouTube Live `,
    btnTitle: '>> Ir <<',
    timeStart: [2020, 9, 6, 0, 0, 0, 0],
    timeEnd: [2020, 9, 6, 23, 59, 0, 0],
    externalLink: 'https://www.youtube.com/watch?v=cfiYItocEes',
    internalLink: ''
  },
  {
    title: 'Miércoles de SOMA ',
    description: `con Alberto Perera. 8:30 pm por YouTube Live `,
    btnTitle: '>> Ir <<',
    timeStart: [2020, 9, 7, 0, 0, 0, 0],
    timeEnd: [2020, 9, 7, 23, 59, 0, 0],
    externalLink: 'https://www.youtube.com/watch?v=cfiYItocEes',
    internalLink: ''
  },
  {
    title: 'Miércoles de SOMA ',
    description: `con Bernardo Núñez Magdaleno. 8:30 pm por YouTube Live `,
    btnTitle: '>> Ir <<',
    timeStart: [2020, 9, 8, 0, 0, 0, 0],
    timeEnd: [2020, 9, 8, 23, 59, 0, 0],
    externalLink: 'https://www.youtube.com/watch?v=Mn5jMB3nf-I',
    internalLink: ''
  },
  {
    title: 'Rat Attack',
    description: `Descarga la publicación de Rat Attack`,
    btnTitle: '>> Descargar <<',
    timeStart: [2020, 9, 9, 0, 0, 0, 0],
    timeEnd: [2020, 9, 9, 23, 59, 0, 0],
    externalLink: 'https://ratattack.somamexico.org/static/media/Hoja_Sala_SOMA_RatAttack.396ae351.pdf',
    internalLink: ''
  },
  {
    title: 'Miércoles de SOMA ',
    description: `con Bernardo Núñez Magdaleno. 8:30 pm por YouTube Live `,
    btnTitle: '>> Ir <<',
    timeStart: [2020, 9, 10, 0, 0, 0, 0],
    timeEnd: [2020, 9, 10, 23, 59, 0, 0],
    externalLink: 'https://www.youtube.com/watch?v=Mn5jMB3nf-I',
    internalLink: ''
  },
  {
    title: 'Miércoles de SOMA ',
    description: `con Bernardo Núñez Magdaleno. 8:30 pm por YouTube Live `,
    btnTitle: '>> Ir <<',
    timeStart: [2020, 9, 11, 0, 0, 0, 0],
    timeEnd: [2020, 9, 11, 23, 59, 0, 0],
    externalLink: 'https://www.youtube.com/watch?v=Mn5jMB3nf-I',
    internalLink: ''
  },
  {
    title: '¡Últimas días! ',
    description: `Visita Rat Attack, únicamente con cita previa.`,
    btnTitle: '>> Agendar <<',
    timeStart: [2020, 9, 12, 0, 0, 0, 0],
    timeEnd: [2020, 9, 12, 23, 59, 0, 0],
    externalLink: '',
    internalLink: '/appointment'
  },
  {
    title: 'Miércoles de SOMA ',
    description: `con Bernardo Núñez Magdaleno. 8:30 pm por YouTube Live `,
    btnTitle: '>> Ir <<',
    timeStart: [2020, 9, 13, 0, 0, 0, 0],
    timeEnd: [2020, 9, 13, 23, 59, 0, 0],
    externalLink: 'https://www.youtube.com/watch?v=Mn5jMB3nf-I',
    internalLink: ''
  },
  {
    title: 'Miércoles de SOMA ',
    description: `con Bernardo Núñez Magdaleno. 8:30 pm por YouTube Live `,
    btnTitle: '>> Ir <<',
    timeStart: [2020, 9, 14, 0, 0, 0, 0],
    timeEnd: [2020, 9, 14, 23, 59, 0, 0],
    externalLink: 'https://www.youtube.com/watch?v=Mn5jMB3nf-I',
    internalLink: ''
  },
  {
    title: '¡Últimas días! ',
    description: `Visita Rat Attack, únicamente con cita previa.`,
    btnTitle: '>> Agendar <<',
    timeStart: [2020, 9, 15, 0, 0, 0, 0],
    timeEnd: [2020, 9, 15, 23, 59, 0, 0],
    externalLink: '',
    internalLink: '/appointment'
  },
  {
    title: '¡Últimas días! ',
    description: `Visita Rat Attack, únicamente con cita previa.`,
    btnTitle: '>> Agendar <<',
    timeStart: [2020, 9, 16, 0, 0, 0, 0],
    timeEnd: [2020, 9, 16, 23, 59, 0, 0],
    externalLink: '',
    internalLink: '/appointment'
  },
  {
    title: 'Rat Attack',
    description: `Gracias por explorar la única miniserie de arte contemporáneo conducida por ratas`,
    btnTitle: '>> Aceptar <<',
    timeStart: [2020, 9, 18, 0, 0, 0, 0],
    timeEnd: [2020, 9, 18, 23, 59, 0, 0],
    externalLink: '',
    internalLink: ''
  },
  {
    title: 'Rat Attack',
    description: `Gracias por explorar la única miniserie de arte contemporáneo conducida por ratas`,
    btnTitle: '>> Aceptar <<',
    timeStart: [2020, 9, 19, 0, 0, 0, 0],
    timeEnd: [2020, 9, 19, 23, 59, 0, 0],
    externalLink: '',
    internalLink: ''
  },
  {
    title: 'Rat Attack',
    description: `Gracias por explorar la única miniserie de arte contemporáneo conducida por ratas`,
    btnTitle: '>> Aceptar <<',
    timeStart: [2020, 9, 20, 0, 0, 0, 0],
    timeEnd: [2020, 9, 20, 23, 59, 0, 0],
    externalLink: '',
    internalLink: ''
  },
  {
    title: 'Rat Attack',
    description: `Gracias por explorar la única miniserie de arte contemporáneo conducida por ratas`,
    btnTitle: '>> Aceptar <<',
    timeStart: [2020, 9, 21, 0, 0, 0, 0],
    timeEnd: [2020, 9, 21, 23, 59, 0, 0],
    externalLink: '',
    internalLink: ''
  },
  {
    title: 'Rat Attack',
    description: `Gracias por explorar la única miniserie de arte contemporáneo conducida por ratas`,
    btnTitle: '>> Aceptar <<',
    timeStart: [2020, 9, 22, 0, 0, 0, 0],
    timeEnd: [2020, 9, 22, 23, 59, 0, 0],
    externalLink: '',
    internalLink: ''
  },
  {
    title: 'Rat Attack',
    description: `Gracias por explorar la única miniserie de arte contemporáneo conducida por ratas`,
    btnTitle: '>> Aceptar <<',
    timeStart: [2020, 9, 23, 0, 0, 0, 0],
    timeEnd: [2020, 9, 23, 23, 59, 0, 0],
    externalLink: '',
    internalLink: ''
  },
  {
    title: 'Rat Attack',
    description: `Gracias por explorar la única miniserie de arte contemporáneo conducida por ratas`,
    btnTitle: '>> Aceptar <<',
    timeStart: [2020, 9, 24, 0, 0, 0, 0],
    timeEnd: [2020, 9, 24, 23, 59, 0, 0],
    externalLink: '',
    internalLink: ''
  },
]

/*
  {
    title: 'Visita Rat Attack',
    description: `Únicamente con cita previa.`,
    btnTitle: '>> Agendar <<',
    timeStart: [2020, 9, 7, 0, 0, 0, 0],
    timeEnd: [2020, 9, 7, 23, 59, 0, 0],
    externalLink: '',
    internalLink: '/appointment'
  },


  {
    title: 'Visita Rat Attack',
    description: `Únicamente con cita previa.`,
    btnTitle: '>> Agendar <<',
    timeStart: [2020, 8, 7, 0, 0, 0, 0],
    timeEnd: [2020, 8, 7, 23, 59, 0, 0],
    externalLink: '',
    internalLink: '/appointment'
  },
*/

const Popup = props => {
  const time = Date.now()
    , [popup, setPopup] = useState(null)
    , [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      let notification = popups.find(p => {
        let startAt = new Date(p.timeStart[0], p.timeStart[1], p.timeStart[2], p.timeStart[3], p.timeStart[4], p.timeStart[5], p.timeStart[6]).getTime()
          , endAt = new Date(p.timeEnd[0], p.timeEnd[1], p.timeEnd[2], p.timeEnd[3], p.timeEnd[4], p.timeEnd[5], p.timeEnd[6]).getTime()
        return time >= startAt && time <= endAt
      })
      if (notification) setPopup(notification)
    }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClick = () => {
    if (popup.externalLink.length) window.open(popup.externalLink, '_blank')
    else if (popup.internalLink.length) {
      let link = popup.internalLink
      setPopup(null)
      loaderTransition()
      setTimeout(() => {
        props.history.push(link)
      }, 2400)
    } else {
      setIsVisible(false)
    }
  }

  if (!popup) return null

  if (!isVisible) return null

  return <Modal>
    <div id="popup_container">
      <div id="splat_bg" onClick={() => setIsVisible(false)}></div>
      <div id="drop"></div>
      <div id="popup">
        <div id="close_btn" onClick={() => setIsVisible(false)}>
          <span dangerouslySetInnerHTML={{
            __html: `<?xml version="1.0" encoding="utf-8"?>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="50px" height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
            <style type="text/css">
              .st0{fill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:#000000;stroke-width:5;stroke-linecap:round;stroke-miterlimit:10;}
            </style>
            <path class="st0" d="M34.8,15.2L15.7,34.2"/>
            <path class="st0" d="M15.7,15.2l19.1,19.1"/>
            </svg>`
          }}></span>
        </div>
        <h2 className="title">{popup.title}</h2>
        <p className="description" dangerouslySetInnerHTML={{ __html: popup.description }} />
        <div onClick={onClick} id="enter_btn">{popup.btnTitle}</div>
      </div>
    </div>
  </Modal>
}

export default withRouter(Popup)